import React, { useContext, useEffect, useState } from "react";
import { contextProvider } from "../App";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Checkboxsearch = () => {

    const [searchValue,setSearchValue,searchTag,setSearchTag] = useContext(contextProvider)
    const [tagList,setTagList] = useState([])
    const navigate = useNavigate();
    const localUrl = process.env.REACT_APP_LOCAL_URL;

    const getDynamic = async () =>{
        try {
          const response = await axios.get(`${localUrl}/getTags`)
          setTagList(response.data)
        } catch (error) {
          navigate("/networkerror")
        }
      }
    
      useEffect(()=>{
        getDynamic()
      },[])
      
    
      const valuetag = (e) =>{
        setSearchTag(e.target.value)
      }

  return (
    <div className="checkbox-search">
    <ul className={ "open"} >
    <li><span><input type="radio" name="sValue" onChange={valuetag} value={""}  className="all-checked"/> <span >All</span> </span></li>
      {tagList.map((item,index)=>(
        <React.Fragment key={index} >
          <li><span><input type="radio" name="sValue" onChange={valuetag} value={item.tags}  /> <span>{item.tags}</span> </span></li>
        </React.Fragment>
      ))}
    </ul>

</div>
  )
}

export default Checkboxsearch
