import React, { useState } from 'react';
import './auth.css';
import { Link } from 'react-router-dom';
import AuthHeader from './AuthHeader';

function Forgot() {
  const [email, setEmail] = useState('');
  const [popupData, setPopupData] = useState(null);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    // console.log('Email:', email);

    try {
   
      const response = await fetch('/api/checkEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        const data = await response.json();
        setPopupData(data); 
      } else {

        console.error('Email not found');
        setPopupData(null); 
      }
    } catch (error) {
      console.error('Error checking email:', error);
    }
  };
  const renderPopup = () => {
    if (popupData) {
      return (
        <div className="popup">
          <p>Hi {popupData.id}</p>
          <p>It looks like you lost your username & password.</p>
          <p>User ID: {popupData.id}</p>
          <p>Password: {popupData.password}</p>
        </div>
      );
    }
    return null;
  };

  return (
   <>

<AuthHeader/>

    <div className="main-container">
      <div className="auth-container">
        <div className="container-left">
          <img src="http://d2aq2s4fglfv4y.cloudfront.net/sell-panel-poster.jpg" alt="Description of your image" />
        </div>
        <div className="container-right">
          <div className='heading'>
            <h2>Lost Username or Password?</h2>
            <p>Please enter your email which you first registered with.</p>
          </div>
          <div className='data-container'>
          <div className='box'>
            <h1>1000+</h1>
            <p>Videos & playlist</p>
          </div>
          <div className='box'>
            <h1>100k</h1>
            <p>fans</p>
          </div>
          <div className='box'>
            <h1>300k</h1>
            <p>Hours of streaming content</p>
          </div>
          <div className='box'>
            <h1>0.01%</h1>
            <p>OF content Creator</p>
          </div>
        </div>

          <form onSubmit={handleLogin}>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>
            <button type="submit">SEND EMAIL</button>
          </form>

          {/* Render the popup */}
          {renderPopup()}

          <div className='links'>
            <p >Don't have an account yet? <Link to="/signup">SIGN UP HERE</Link></p>
            {/* <p ><a href="/forgot">Forgot Username or Password?</a>.</p> */}
          </div>
        </div>
      </div>
    </div>
   </>
  );
}

export default Forgot;
