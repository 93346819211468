import React from 'react'
import "../assets/css/404.css"

const NOTFOUND = () => {
  return (
    <div className='not-found' >
        <h1 >404</h1>
        <h3 >PAGE NOT FOUND</h3>
    </div>
  )
}

export default NOTFOUND