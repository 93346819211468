import React, { useContext, useEffect, useState } from "react";
import "../assets/css/home.css";
import 'react-loading-skeleton/dist/skeleton.css'
import Card from "../componant/Card";
import Video from "../componant/Video";
import Header from "../componant/Header";
import img3 from "../assets/img/of_white (2) (3).svg";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import axios from "axios";
import Carousel from "../componant/Carousel";
import CarouselM from "../componant/CarouselM";
import { BoughtVideosProvider, contextProvider ,firstProvider, CoinsProvider, CartProvider} from "../App";
import Footer from "../componant/Footer";
import Button from "../componant/Button";
import Checkboxsearch from "../componant/Checkbox-search";
import CategorySub from "../componant/CategorySub";
import { Audio ,Oval } from 'react-loader-spinner';
import RedVideos from "./RedVideos";
import Radio from "../componant/Radio";
import { Navigate, useNavigate } from "react-router-dom";

const Home = () => {
  const [searchValue,setSearchValue,searchTag,setSearchTag, tab, setTab] = useContext(contextProvider)
  const [catSec,setCatSec,redVideos,setRedVideos]= useContext(firstProvider);
  const {boughtVids, setBoughtVids} = useContext(BoughtVideosProvider);
  const {coins, setCoins}= useContext(CoinsProvider);
  const {isCartOpen, setIsCartOpen} = useContext(CartProvider);
  // const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading,setIsLoading] = useState(true);
  const loadingArray = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17];

  const [currentPage, setCurrentPage] = useState(1);
  const [mobile,setMobile] = useState(true)
  const itemsPerPage = 48;

  const [vidUrl, setVidUrl] = useState("");
  const [showVideo, setShowVideo] = useState(false);

  const navigate = useNavigate();

  const localUrl = process.env.REACT_APP_LOCAL_URL;
  const oldUrl = process.env.REACT_APP_REMOTE_URL;


  const getUrl = (e) => {
    setVidUrl(e);
  };

  const ShowVideohandler = (e) => {
    setShowVideo(e);
  };

  const hideVideo = (e) => {
    setShowVideo(e);
  };

  const homePage = (e) =>{
    setCurrentPage(e)
  }

  const filteredCards = data.filter((card) =>
    card.video_title.toLowerCase().includes(searchValue.toLowerCase())
  );


  // Calculate total number of pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Get data for current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentData = data.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  useEffect(()=>{
    if(window.innerWidth < "768" || window.clientWidth < "768"){
      setMobile(false)
    }else{
      setMobile(true)
    }
  },[window.innerWidth])

  const getData = async () => {
    const response = await axios.get(
     `${localUrl}/getData?searchme=${searchValue}`
    );
    setData(response.data);
    setIsLoading(false)
    setCurrentPage(1)
  };

  const content = (redVideos)=>{
    if(redVideos === "exclusive-vid"){
      return <ExclusiveVideos/>
    }else if(redVideos === "my-vid"){
      return <MyVideos/>
    }else if(redVideos === "quickies-vid"){
      return <Quickies/>
    }else if(redVideos === "premium-vid"){
      return <AllVideos/>
    }
  }

  useEffect(()=>{
    content();
  },[redVideos])
  return (
    <React.Fragment>
      <div id="container" className="container">
      <Header searchTerm={searchTerm} homePage={homePage} hideHeader={true} hideNavMenu={true}
       boughtVids={boughtVids} setBoughtVids={setBoughtVids} coins={coins} setCoins={setCoins}
       isCartOpen={isCartOpen} setIsCartOpen={setIsCartOpen}/>
         <span className="home" >{catSec ?  <CategorySub/>  :<Checkboxsearch/>  }</span>
       {mobile ? <Carousel/> : <CarouselM/>}
        {content(redVideos)}
      </div>
      {showVideo && <Video vidUrl={vidUrl} hideVideo={hideVideo} />}
      <Footer/>
      <Button/>
      
    </React.Fragment>
  );
};


function AllVideos(){

  const [searchValue,setSearchValue,searchTag,setSearchTag, tab, setTab] = useContext(contextProvider)
  const [catSec,setCatSec,redVideos,setRedVideos]= useContext(firstProvider);
  const [mobile,setMobile] = useState(true)
  const navigate = useNavigate();
  const [isLoading,setIsLoading] = useState(true);
  const [showVideo, setShowVideo] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [vidUrl, setVidUrl] = useState(""); 
  const [data, setData] = useState([]);
  const loadingArray = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17];
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [myData, setMyData] = useState([]);//array to store id's of the videos which we bought
  const {isCartOpen, setIsCartOpen} = useContext(CartProvider);
  const itemsPerPage = 48;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  
  const currentData = data.slice(startIndex, endIndex);
  

  const totalPages = Math.ceil(data.length / itemsPerPage);
  


  const localUrl = process.env.REACT_APP_LOCAL_URL;

  const ShowVideohandler = (e) => {
    setShowVideo(e);
  };

  const getUrl = (e) => {
    setVidUrl(e);
  };

  const hideVideo = (e) => {
    setShowVideo(e);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  
  const getData = async () => {
    const vid_type = "PREMIUM VIDEOS";
   try {
    const response = await axios.get(
      `${localUrl}/getData?Video_type=${vid_type}&searchme=${searchValue}`
     );
     setData(response.data);
     setIsLoading(false)
     setCurrentPage(1)
   } catch (error) {
    navigate("/networkerror");
   }
  };
  
  const getBoughtData = async () => {
    const vid_type = "PREMIUM VIDEOS";
    const user_id = localStorage.getItem("token_id");
    if(user_id!== null){
      try {
        const response = await axios.get(
          `${localUrl}/getData?Video_type=${vid_type}&searchme=${searchValue}`
         );
         let vidIdArray = [];
         let data = response.data
         data.forEach(element => {
           vidIdArray.push(element.video_id);
         });
         setMyData(vidIdArray);
      } catch (error) {
        navigate("/networkerror")
      }
    }
  };
  
  const getDataTag = async () =>{
    const vid_type = "PREMIUM VIDEOS";
    try {
      const response = await axios.get(
        `${localUrl}/getData?Video_type=${vid_type}&searchme=${searchTag}`
       );
       setData(response.data);
       setIsLoading(false)
       setCurrentPage(1)
    } catch (error) {
      navigate("/networkerror")
    }
  }
  useEffect(()=>{
    getData();
  },[])

  useEffect(()=>{
    getDataTag()
  },[searchTag])

  useEffect(()=>{
    getData();
    getBoughtData();
  },[])

  useEffect(() => {
    getData();
  }, [searchValue]);




    return (
      <>
      <span className="home" >{catSec ?  <CategorySub/>  :<Checkboxsearch/>  }</span>
       {/* {mobile ? <Carousel/> : <CarouselM/>} */}
        { isLoading ? <>
          <div className="card-container">
          {loadingArray.map((card) => (
            <div className="card" key={card} >
         <div
           className="card-image">
          <SkeletonTheme highlightColor='#F8AD00' > <div style={{width:"100%",height:"100%"}} >
               <Skeleton height={"100%"} />
             </div> </SkeletonTheme>
         </div>
         <h4></h4>
         <p>
         <SkeletonTheme highlightColor='#F8AD00' > <div style={{width:"100%",height:"100%"}} >
               <Skeleton height={"100%"} />
             </div> </SkeletonTheme>
     
         </p>
         <div className="buttons">
           <button className="btn-1">
             <img src={img3} alt="" className="main-image"></img>
             <h1>VIP</h1>
           </button>
           <button className="btn-2">
             <img src={img3} alt="" className="main-image"></img>
             <h1>FREE</h1>
           </button>
            
         </div>
       </div>
          ))}
        </div>
        </> : <>

        <div className="card-container">
          {currentData.map((card) => (
            
            <React.Fragment key={`${card.rowid}${Math.random()* 1000 + 1}`} >
              
              <Card
              isLoading={isLoading}
              key={card.rowid}
              title={card.video_title}
              rowid={card.rowid}
              getUrl={getUrl}
              ShowVideohandler={ShowVideohandler}
              vidUrl={card.video_url}
              poster={card.poster_image}
              video_desc={card.video_desc}
              duration={card.duration}
              vip_price={card.vip_price}
              video_id={card.video_id_only}
              video_id_part={card.video_id_part}
              vid_tag ={card.video_title.startsWith("QUICKIE") ? "QUICKIE ID" : "VIDEO ID"}
              isbought={true}
              isCartOpen={isCartOpen}
              setIsCartOpen={setIsCartOpen}
              // doIBought = {myData.includes(card.video_id)}
              vidType= {"premium"}
              // addToBoughtVideos = {addToBoughtVideos(card.video_title, card.poster_image, card.video_id)}
            />
            </React.Fragment>
          ))}
        </div>
        </>}
        <div>
      {/* Rest of the component */}
    </div>
        <div className="pagination">
          {/* {currentPage > 1 && ( */}
            <a href="#container"
              className="pagination-button"
              onClick={handlePrevPage}
            >
              Prev
            </a>
          {/* )} */}
          {Array.from({ length: totalPages }, (_, index) => (
            <React.Fragment key={index} >
              <a href="#container"
              className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              disabled={currentPage === index + 1}
            >
              {index + 1}
            </a>
            </React.Fragment>
          ))}
          {/* {currentPage < totalPages && ( */}
            <a href="#container"
              className="pagination-button"
              onClick={handleNextPage}
            >
              Next
            </a>
          {/* )} */}
        </div>
        {showVideo && <Video vidUrl={vidUrl} hideVideo={hideVideo} />}
    </>
    )
  
}

function Quickies(){

  const [searchValue,setSearchValue,searchTag,setSearchTag, tab, setTab] = useContext(contextProvider)
  const [catSec,setCatSec,redVideos,setRedVideos]= useContext(firstProvider);
  const [mobile,setMobile] = useState(true)
  const [isLoading,setIsLoading] = useState(true);
  const [showVideo, setShowVideo] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [vidUrl, setVidUrl] = useState(""); 
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const loadingArray = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17];
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [myData, setMyData] = useState([]);//array to store id's of the videos which we bought
  const {isCartOpen, setIsCartOpen} = useContext(CartProvider);
  const itemsPerPage = 48;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = data.slice(startIndex, endIndex);
  

  const totalPages = Math.ceil(data.length / itemsPerPage);
  


  const localUrl = process.env.REACT_APP_LOCAL_URL;

  const ShowVideohandler = (e) => {
    setShowVideo(e);
  };

  const getUrl = (e) => {
    setVidUrl(e);
  };

  const hideVideo = (e) => {
    setShowVideo(e);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  
  const getData = async () => {
    try {
      
      const response = await axios.get(
        `${localUrl}/getData?Video_type=quickies&searchme=${searchValue}`
      );
      setData(response.data);
      setIsLoading(false)
      setCurrentPage(1)
    } catch (error) {
        navigate("/networkerror")
    }
  };

  const getBoughtData = async () => {
    const user_id = localStorage.getItem("token_id");
    if(user_id!== null){
      try {
        const response = await axios.get(
          `${localUrl}/purchased_video?user_id=${user_id}`
         );
         let vidIdArray = [];
         let data = response.data
         data.forEach(element => {
           vidIdArray.push(element.video_id);
         });
         setMyData(vidIdArray);
      } catch (error) {
        navigate("/networkerror")
      }
    }
  };
  const getDataTag = async () =>{
    
    try {
      const response = await axios.get(
        `${localUrl}/getData?Video_type=quickies&searchme=${searchTag}`
       );
  
       setData(response.data);
       setIsLoading(false)
       setCurrentPage(1)
    } catch (error) {
      navigate("/networkerror")
      
    }
  }
  
  useEffect(()=>{
    getDataTag()
  },[searchTag])

  useEffect(()=>{
      getData();
    getBoughtData();
  },[])
  
  useEffect(() => {
    getData();
  }, [searchValue]);

    return (
      <>
      <span className="home" >{catSec ?  <CategorySub/>  :<Checkboxsearch/>  }</span>
       {/* {mobile ? <Carousel/> : <CarouselM/>} */}
        { isLoading ? <>
          <div className="card-container">
          {loadingArray.map((card) => (
            <div className="card" key={card} >
         <div
           className="card-image">
          <SkeletonTheme highlightColor='#F8AD00' > <div style={{width:"100%",height:"100%"}} >
               <Skeleton height={"100%"} />
             </div> </SkeletonTheme>
         </div>
         <h4></h4>
         <p>
         <SkeletonTheme highlightColor='#F8AD00' > <div style={{width:"100%",height:"100%"}} >
               <Skeleton height={"100%"} />
             </div> </SkeletonTheme>
     
         </p>
         <div className="buttons">
           <button className="btn-1">
             <img src={img3} alt="" className="main-image"></img>
             <h1>VIP</h1>
           </button>
           <button className="btn-2">
             <img src={img3} alt="" className="main-image"></img>
             <h1>FREE</h1>
           </button>
            
           {/* <button className="buy-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="36.825" height="30.688" viewBox="0 0 36.825 30.688"><g id="cart_mf" transform="translate(0 -4)"><path id="Path_3" data-name="Path 3" d="M29.787,27.016H15.359a3.833,3.833,0,0,1-3.684-2.786L6.8,7.2a2.3,2.3,0,0,0-2.21-1.669H.767A.767.767,0,1,1,.767,4H4.588A3.832,3.832,0,0,1,8.272,6.786l.962,3.351H34.523a2.3,2.3,0,0,1,2.2,2.961l-3.3,11.278A3.83,3.83,0,0,1,29.787,27.016ZM9.673,11.672l3.478,12.14a2.293,2.293,0,0,0,2.208,1.669H29.787A2.317,2.317,0,0,0,31.96,23.92l3.3-11.259a.768.768,0,0,0-.735-.988Zm7.206,23.016a3.069,3.069,0,1,1,3.069-3.069A3.072,3.072,0,0,1,16.878,34.688Zm0-4.6a1.536,1.536,0,1,0,1.467,1.534A1.536,1.536,0,0,0,16.878,30.085Zm10.741,4.6a3.069,3.069,0,1,1,3.069-3.069A3.072,3.072,0,0,1,27.619,34.688Zm0-4.6a1.536,1.536,0,1,0,1.467,1.534,1.536,1.536,0,0,0-1.467-1.534Z" fill="#fff"></path></g></svg>
            </button> */}
           {/* <button className="btn-2">
             <img src={img3} alt="" className="main-image"></img>
             <h1>CART</h1>
           </button> */}
            {/* <button className="buy-btn"  >
            <Oval height={20} width={20} color="#FF9900" wrapperStyle={{}} wrapperClass="" visible={true} ariaLabel='oval-loading' secondaryColor="#4fa94d" strokeWidth={2} strokeWidthSecondary={2}/>
            </button> */}
         </div>
       </div>
          ))}
        </div>
        </> : <>

        <div className="card-container">
          {currentData.map((card) => (
            
            <React.Fragment key={`${card.rowid}${Math.random()* 1000 + 1}`} >
              
              <Card
              isLoading={isLoading}
              key={card.rowid}
              title={card.video_title}
              rowid={card.rowid}
              getUrl={getUrl}
              ShowVideohandler={ShowVideohandler}
              vidUrl={card.video_url}
              poster={card.poster_image}
              video_desc={card.video_desc}
              duration={card.duration}
              vip_price={card.vip_price}
              video_id={card.video_id_only}
              video_id_part={card.video_id_part}
              vid_tag ={"QUICKIE ID"}
              isbought={true}
              isCartOpen={isCartOpen}
              setIsCartOpen={setIsCartOpen}
              doIBought = {myData.includes(card.video_id)}
              vidType={"quickies"}
              // addToBoughtVideos = {addToBoughtVideos(card.video_title, card.poster_image, card.video_id)}
            />
            </React.Fragment>
          ))}
        </div>
        </>}
        <div>
      {/* Rest of the component */}
    </div>
        {data.length === 0 ? 
        <></>:
        <div className="pagination">
            <a href="#container"
              className="pagination-button"
              onClick={handlePrevPage}
            >
              Prev
            </a>
          {Array.from({ length: totalPages }, (_, index) => (
            <React.Fragment key={index} >
              <a href="#container"
              className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              disabled={currentPage === index + 1}
            >
              {index + 1}
            </a>
            </React.Fragment>
          ))}
        
            <a href="#container"
              className="pagination-button"
              onClick={handleNextPage}
            >
              Next
            </a>
        
        </div>}
        {showVideo && <Video vidUrl={vidUrl} hideVideo={hideVideo} />}
    </>
    )
  
}

function ExclusiveVideos(){

  const [searchValue,setSearchValue,searchTag,setSearchTag, tab, setTab] = useContext(contextProvider)
  const [catSec,setCatSec,redVideos,setRedVideos]= useContext(firstProvider);
  const [mobile,setMobile] = useState(true)
  const [isLoading,setIsLoading] = useState(true);
  const [showVideo, setShowVideo] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [vidUrl, setVidUrl] = useState(""); 
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const loadingArray = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17];
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [myData, setMyData] = useState([]);//array to store id's of the videos which we bought
  const {isCartOpen, setIsCartOpen} = useContext(CartProvider);
  const itemsPerPage = 48;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = data.slice(startIndex, endIndex);
  
  const totalPages = Math.ceil(data.length / itemsPerPage);

  const localUrl = process.env.REACT_APP_LOCAL_URL;

  const ShowVideohandler = (e) => {
    setShowVideo(e);
  };

  const getUrl = (e) => {
    setVidUrl(e);
  };

  const hideVideo = (e) => {
    setShowVideo(e);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  
  const getData = async () => {
    
  try {
    const response = await axios.get(
      `${localUrl}/getData?Video_type=exclusive&searchme=${searchValue}`
     );
     setData(response.data);
     setIsLoading(false)
     setCurrentPage(1)
  } catch (error) {
    navigate("/networkerror")
  }
  };

  const getBoughtData = async () => {
    const user_id = localStorage.getItem("token_id");
    if(user_id!== null){
      try {
        const response = await axios.get(
          `${localUrl}/purchased_video?user_id=${user_id}`
         );
         let vidIdArray = [];
         let data = response.data
         data.forEach(element => {
           vidIdArray.push(element.video_id);
         });
         setMyData(vidIdArray);
      } catch (error) {
        navigate("/networkerror")
      }
    }
  };
  useEffect(()=>{
      getData();
    getBoughtData();
  },[])
  
  const getDataTag = async () =>{
    try {
      const response = await axios.get(
        `${localUrl}/getData?Video_type=exclusive&searchme=${searchTag}`
       );
       setData(response.data);
       setIsLoading(false)
       setCurrentPage(1)
    } catch (error) {
      navigate("/networkerror")
      
    }
  }
  
  useEffect(()=>{
    getDataTag()
  },[searchTag])

  useEffect(() => {
    getData();
  }, [searchValue]);

    return (
      <>
      <span className="home" >{catSec ?  <CategorySub/>  :<Checkboxsearch/>  }</span>
        { isLoading ? <>
          <div className="card-container">
          {loadingArray.map((card) => (
            <div className="card" key={card} >
         <div
           className="card-image">
          <SkeletonTheme highlightColor='#F8AD00' > <div style={{width:"100%",height:"100%"}} >
               <Skeleton height={"100%"} />
             </div> </SkeletonTheme>
         </div>
         <h4></h4>
         <p>
         <SkeletonTheme highlightColor='#F8AD00' > <div style={{width:"100%",height:"100%"}} >
               <Skeleton height={"100%"} />
             </div> </SkeletonTheme>
     
         </p>
         <div className="buttons">
           <button className="btn-1">
             <img src={img3} alt="" className="main-image"></img>
             <h1>VIP</h1>
           </button>
           <button className="btn-2">
             <img src={img3} alt="" className="main-image"></img>
             <h1>FREE</h1>
           </button>
            
           {/* <button className="buy-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="36.825" height="30.688" viewBox="0 0 36.825 30.688"><g id="cart_mf" transform="translate(0 -4)"><path id="Path_3" data-name="Path 3" d="M29.787,27.016H15.359a3.833,3.833,0,0,1-3.684-2.786L6.8,7.2a2.3,2.3,0,0,0-2.21-1.669H.767A.767.767,0,1,1,.767,4H4.588A3.832,3.832,0,0,1,8.272,6.786l.962,3.351H34.523a2.3,2.3,0,0,1,2.2,2.961l-3.3,11.278A3.83,3.83,0,0,1,29.787,27.016ZM9.673,11.672l3.478,12.14a2.293,2.293,0,0,0,2.208,1.669H29.787A2.317,2.317,0,0,0,31.96,23.92l3.3-11.259a.768.768,0,0,0-.735-.988Zm7.206,23.016a3.069,3.069,0,1,1,3.069-3.069A3.072,3.072,0,0,1,16.878,34.688Zm0-4.6a1.536,1.536,0,1,0,1.467,1.534A1.536,1.536,0,0,0,16.878,30.085Zm10.741,4.6a3.069,3.069,0,1,1,3.069-3.069A3.072,3.072,0,0,1,27.619,34.688Zm0-4.6a1.536,1.536,0,1,0,1.467,1.534,1.536,1.536,0,0,0-1.467-1.534Z" fill="#fff"></path></g></svg>
            </button> */}
           {/* <button className="btn-2">
             <img src={img3} alt="" className="main-image"></img>
             <h1>CART</h1>
           </button> */}
            {/* <button className="buy-btn"  >
            <Oval height={20} width={20} color="#FF9900" wrapperStyle={{}} wrapperClass="" visible={true} ariaLabel='oval-loading' secondaryColor="#4fa94d" strokeWidth={2} strokeWidthSecondary={2}/>
            </button> */}
         </div>
       </div>
          ))}
        </div>
        </> : <>

        <div className="card-container">
          {currentData.map((card) => (
            
            <React.Fragment key={`${card.rowid}${Math.random()* 1000 + 1}`} >
              
              <Card
              isLoading={isLoading}
              key={card.rowid}
              title={card.video_title}
              rowid={card.rowid}
              getUrl={getUrl}
              ShowVideohandler={ShowVideohandler}
              vidUrl={card.video_url}
              poster={card.poster_image}
              video_desc={card.video_desc}
              duration={card.duration}
              vip_price={card.vip_price}
              video_id={card.video_id_only}
              video_id_part={card.video_id_part}
              vid_tag ={"Exlusive ID"}
              isbought={true}
              isCartOpen={isCartOpen}
              setIsCartOpen={setIsCartOpen}
              doIBought = {myData.includes(card.video_id)}
              vidType={"exc-vids"}
              // addToBoughtVideos = {addToBoughtVideos(card.video_title, card.poster_image, card.video_id)}
            />
            </React.Fragment>
          ))}
        </div>
        </>}
        <div>
      {/* Rest of the component */}
    </div>
        <div className="pagination">
          {/* {currentPage > 1 && ( */}
            <a href="#container"
              className="pagination-button"
              onClick={handlePrevPage}
            >
              Prev
            </a>
          {/* )} */}
          {Array.from({ length: totalPages }, (_, index) => (
            <React.Fragment key={index} >
              <a href="#container"
              className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              disabled={currentPage === index + 1}
            >
              {index + 1}
            </a>
            </React.Fragment>
          ))}
          {/* {currentPage < totalPages && ( */}
            <a href="#container"
              className="pagination-button"
              onClick={handleNextPage}
            >
              Next
            </a>
          {/* )} */}
        </div>
        {showVideo && <Video vidUrl={vidUrl} hideVideo={hideVideo} />}
    </>
    )
  
}

function MyVideos() {
  const [searchValue,setSearchValue,searchTag,setSearchTag, tab, setTab] = useContext(contextProvider)
  const [catSec,setCatSec,redVideos,setRedVideos]= useContext(firstProvider);
  const [mobile,setMobile] = useState(true)
  const [isLoading,setIsLoading] = useState(true);
  const [showVideo, setShowVideo] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [vidUrl, setVidUrl] = useState(""); 
  const [data, setData] = useState([]);
  const loadingArray = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17];
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [myData, setMyData] = useState([]);//array to store id's of the videos which we bought
  const {isCartOpen, setIsCartOpen} = useContext(CartProvider);
  const navigate = useNavigate();
  const itemsPerPage = 48;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = data.slice(startIndex, endIndex);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const localUrl = process.env.REACT_APP_LOCAL_URL;

  const ShowVideohandler = (e) => {
    setShowVideo(e);
  };

  const getUrl = (e) => {
    setVidUrl(e);
  };

  const hideVideo = (e) => {
    setShowVideo(e);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  
  const getData = async () => {
    try {
      const user_id = localStorage.getItem("token_id");
      if(user_id !== null){
        const response = await axios.get(`${localUrl}/purchased_video?user_id=${user_id}`);
        setData(response.data);
        setIsLoading(false)
        setCurrentPage(1)
      }else{
        setData([]);
        setCurrentPage(1)
        setIsLoading(false)
      }
    } catch (error) {
      navigate("/networkerror");
    }
  };

  const getBoughtData = async () => {
    const user_id = localStorage.getItem("token_id");
    if(user_id!== null){
      try {
        const response = await axios.get(
          `${localUrl}/purchased_video?user_id=${user_id}`
         );
         let vidIdArray = [];
         let data = response.data
         data.forEach(element => {
           vidIdArray.push(element.video_id);
         });
         setMyData(vidIdArray);
      } catch (error) {
        navigate("/networkerror");
      }
    }
  };

  useEffect(()=>{
      getData();
    getBoughtData();
  },[])
  
  useEffect(() => {
    getData();
  }, [searchValue]);

    return (
      <>
      {data.length === 0 ? 
      <span className="home"></span> 
      : 
      <span className="home" >{catSec ?  <CategorySub/>  :<Checkboxsearch/>  }</span>}
        { isLoading ? <>
          <div className="card-container">
          {loadingArray.map((card) => (
            <div className="card" key={card} >
         <div
           className="card-image">
          <SkeletonTheme highlightColor='#F8AD00' > <div style={{width:"100%",height:"100%"}} >
               <Skeleton height={"100%"} />
             </div> </SkeletonTheme>
         </div>
         <h4></h4>
         <p>
         <SkeletonTheme highlightColor='#F8AD00' > <div style={{width:"100%",height:"100%"}} >
               <Skeleton height={"100%"} />
             </div> </SkeletonTheme>
     
         </p>
         <div className="buttons">
           <button className="btn-1">
             <img src={img3} alt="" className="main-image"></img>
             <h1>VIP</h1>
           </button>
           <button className="btn-2">
             <img src={img3} alt="" className="main-image"></img>
             <h1>FREE</h1>
           </button>
            
           {/* <button className="buy-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="36.825" height="30.688" viewBox="0 0 36.825 30.688"><g id="cart_mf" transform="translate(0 -4)"><path id="Path_3" data-name="Path 3" d="M29.787,27.016H15.359a3.833,3.833,0,0,1-3.684-2.786L6.8,7.2a2.3,2.3,0,0,0-2.21-1.669H.767A.767.767,0,1,1,.767,4H4.588A3.832,3.832,0,0,1,8.272,6.786l.962,3.351H34.523a2.3,2.3,0,0,1,2.2,2.961l-3.3,11.278A3.83,3.83,0,0,1,29.787,27.016ZM9.673,11.672l3.478,12.14a2.293,2.293,0,0,0,2.208,1.669H29.787A2.317,2.317,0,0,0,31.96,23.92l3.3-11.259a.768.768,0,0,0-.735-.988Zm7.206,23.016a3.069,3.069,0,1,1,3.069-3.069A3.072,3.072,0,0,1,16.878,34.688Zm0-4.6a1.536,1.536,0,1,0,1.467,1.534A1.536,1.536,0,0,0,16.878,30.085Zm10.741,4.6a3.069,3.069,0,1,1,3.069-3.069A3.072,3.072,0,0,1,27.619,34.688Zm0-4.6a1.536,1.536,0,1,0,1.467,1.534,1.536,1.536,0,0,0-1.467-1.534Z" fill="#fff"></path></g></svg>
            </button> */}
           {/* <button className="btn-2">
             <img src={img3} alt="" className="main-image"></img>
             <h1>CART</h1>
           </button> */}
            {/* <button className="buy-btn"  >
            <Oval height={20} width={20} color="#FF9900" wrapperStyle={{}} wrapperClass="" visible={true} ariaLabel='oval-loading' secondaryColor="#4fa94d" strokeWidth={2} strokeWidthSecondary={2}/>
            </button> */}
         </div>
       </div>
          ))}
        </div>
        </> : <>
        {data.length === 0 ? 
          <h1 className="mydata-info">You don't have any videos... Buy Videos. 
          Or <span style={{cursor:"pointer", padding:"0 1%", color:"white"}} onClick={()=> navigate('/login')}>Login here</span> To buy.
          </h1>
        :
        <></>}
        <div className="card-container">

        
          {currentData.map((card) => (
            
            <React.Fragment key={`${card.rowid}${Math.random()* 1000 + 1}`} >
              
              <Card
              isLoading={isLoading}
              key={card.rowid}
              title={card.video_title}
              rowid={card.rowid}
              getUrl={getUrl}
              ShowVideohandler={ShowVideohandler}
              vidUrl={card.long_video_url}
              poster={card.poster_image}
              video_desc={card.video_desc}
              duration={card.duration}
              vip_price={card.vip_price}
              video_id={card.video_id_only}
              video_id_part={card.video_id_part}
              vid_tag ={card.video_title.startsWith("QUICKIE") ? "QUICKIE ID" : "VIDEO ID"}
              isbought={true}
              isCartOpen={isCartOpen}
              setIsCartOpen={setIsCartOpen}
              vidType = {"my_vid"}
              doIBought = {true}
              // addToBoughtVideos = {addToBoughtVideos(card.video_title, card.poster_image, card.video_id)}
            />
            </React.Fragment>
          ))}
        </div>
        </>}
        <div>
      
    </div>
        {data.length === 0 ? 
          <> </>
        :
        <div className="pagination">
          
              <a href="#container"
              className="pagination-button"
              onClick={handlePrevPage}
            >
              Prev
            </a>
        
          {Array.from({ length: totalPages }, (_, index) => (
            <React.Fragment key={index} >
              <a href="#container"
              className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              disabled={currentPage === index + 1}
            >
              {index + 1}
            </a>
            </React.Fragment>
          ))}
         
            <a href="#container"
              className="pagination-button"
              onClick={handleNextPage}
            >
              Next
            </a>
        
        </div>}
        {showVideo && <Video vidUrl={vidUrl} hideVideo={hideVideo} />}
    </>
    )
}


export default Home;
