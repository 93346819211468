import React, { useEffect } from 'react'
import "../assets/css/404.css"
import { Link, useNavigate } from 'react-router-dom';

function NetworkError() {
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate('/');
    }, 5000); // Redirect after 5 seconds, you can adjust the time as needed

    return () => clearTimeout(timeout);
  }, [navigate]);

  return (
    <div className='not-found'>
      <h1>CONNECTION ERROR</h1>
      <h3>Trying to redirect to <Link to='/'>home</Link>...</h3>
    </div>
  );
}

export default NetworkError;
