import React from 'react'
import "../assets/css/logoutpopup.css"

function LogoutPopUp({logoutPopUP, setLogOutPopUp, logout}) {

  return (
    <div className='logout-popup'>
        <div className='logout-popup-content'>
            <p className='logout-question'>Do you really want to logout? </p>
            <button onClick={()=>setLogOutPopUp(false)}>No</button>
            <button onClick={()=>logout}>Yes</button>
        </div>
    </div>
  )
}

export default LogoutPopUp
