import '../assets/css/header.css';
import { Link } from 'react-router-dom';
const AuthHeader = () => {
  return (
    <div className='auth-header'>
        <Link to="/"  className='logo'>
          <svg xmlns="http://www.w3.org/2000/svg" width="128.68" height="28" viewBox="0 0 128.68 28">
            <g id="Group_126" data-name="Group 126" transform="translate(18444.68 920)">
              <g id="Group_125" data-name="Group 125" transform="translate(-18474 -946)">
                <path id="Path_1" data-name="Path 1" d="M5,0H47a5,5,0,0,1,5,5V23a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z" transform="translate(106 26)" fill="#f8981d"/>
                <path id="Path_67" data-name="Path 67" d="M15.78,0V-13.34h-4L8.54-5.02,5.3-13.34H1.32V0H4.16V-9.6L7.92,0H9.16l3.76-9.6V0ZM27.6,0V-6.24c0-2.78-2.02-3.66-4.22-3.66a6.337,6.337,0,0,0-4.22,1.52l.96,1.7a4.065,4.065,0,0,1,2.82-1.14c1.28,0,2.12.64,2.12,1.62v1.34A3.9,3.9,0,0,0,22-6.04a3.059,3.059,0,0,0-3.36,3.08A3.218,3.218,0,0,0,22,.24,4,4,0,0,0,25.06-1V0ZM25.06-2.32a2.541,2.541,0,0,1-2.04.84c-1,0-1.82-.52-1.82-1.42,0-.92.82-1.46,1.82-1.46a2.541,2.541,0,0,1,2.04.84Zm5.62,6.1a6.943,6.943,0,0,0,1.26.14A3.51,3.51,0,0,0,35.6,1.58L40.14-9.66H37.4L34.88-2.94,32.36-9.66H29.64L33.56.14,33.2.96a1.281,1.281,0,0,1-1.34.7,2.195,2.195,0,0,1-.8-.16ZM44.72,0V-13.34H42.18V0Zm2.8-4.84A4.892,4.892,0,0,0,52.68.24,5.954,5.954,0,0,0,56.66-1.1L55.54-2.74a3.95,3.95,0,0,1-2.58.96,2.6,2.6,0,0,1-2.78-2.24h7.18v-.56c0-3.16-1.96-5.32-4.84-5.32A4.916,4.916,0,0,0,47.52-4.84Zm5-3.04A2.238,2.238,0,0,1,54.9-5.74H50.14A2.321,2.321,0,0,1,52.52-7.88Zm6.96,3.04A4.892,4.892,0,0,0,64.64.24,5.953,5.953,0,0,0,68.62-1.1L67.5-2.74a3.95,3.95,0,0,1-2.58.96,2.6,2.6,0,0,1-2.78-2.24h7.18v-.56c0-3.16-1.96-5.32-4.84-5.32A4.916,4.916,0,0,0,59.48-4.84Zm5-3.04a2.238,2.238,0,0,1,2.38,2.14H62.1A2.321,2.321,0,0,1,64.48-7.88Z" transform="translate(28 47)" fill="#fff"/>
                <path id="Path_68" data-name="Path 68" d="M4.16,0V-5.56h6.46V-8H4.16v-2.9h6.6v-2.44H1.32V0ZM22.64,0V-9.66H20.1v6.54a2.843,2.843,0,0,1-2.2,1.1A1.553,1.553,0,0,1,16.12-3.8V-9.66H13.58V-2.8A2.766,2.766,0,0,0,16.7.24a4.521,4.521,0,0,0,3.4-1.46V0ZM35.12,0V-6.82A2.81,2.81,0,0,0,31.98-9.9,4.423,4.423,0,0,0,28.6-8.4V-9.66H26.06V0H28.6V-6.5a2.751,2.751,0,0,1,2.2-1.14,1.567,1.567,0,0,1,1.78,1.8V0Z" transform="translate(113 47)"/>
              </g>
            </g>
          </svg>
        </Link>
    </div>
  )
}

export default AuthHeader