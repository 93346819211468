import React from 'react'
import "../assets/css/cancel.css"
import { Link } from 'react-router-dom'

const Cancel = () => {
  return (
    <section className='cancel-page'>
      <h1>You Canceled Payment</h1>
      <Link to="/"  className='logo'>
      <svg xmlns="http://www.w3.org/2000/svg" width="113" height="28" viewBox="0 0 113 28">
  <g id="Group_1" data-name="Group 1" transform="translate(-45 -26)">
    <path id="Path_1" data-name="Path 1" d="M5,0H47a5,5,0,0,1,5,5V23a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z" transform="translate(106 26)" fill="#f8981d"/>
    <text id="Malee-text" transform="translate(45 47)" fill="#fff" fontSize="20"  fontWeight="700" letterSpacing="0.045em"><tspan x="0" y="0">Malee</tspan></text>
    <text id="Fun-text" transform="translate(113 47)" fontSize="20"  fontWeight="700" letterSpacing="0.045em"><tspan x="0" y="0">Fun</tspan></text>
  </g>
</svg>
        </Link>
    </section>
  )
}

export default Cancel