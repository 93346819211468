import { createContext,  useEffect, useState } from 'react';
import './App.css';
import './assets/css/header.css'
import Home from './pages/Home';
import { BrowserRouter  ,Routes, Route, useNavigate  } from 'react-router-dom';
import NOTFOUND from './pages/NOTFOUND';
import Login from './auth/Login';
import Signup from './auth/Signup';
import Forgot from './auth/Forgot-password';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Success from './pages/Success';
import Cancel from './pages/Cancel';
import RedVideos from './pages/RedVideos';
import CartPage from './pages/CartPage';
import MyVideos from './pages/MyVideos';
import NetworkError from './pages/NetworkError';

export const contextProvider = createContext();
export const firstProvider = createContext();
export const BoughtVideosProvider = createContext();
export const CoinsProvider = createContext();
export const CartProvider = createContext()

function App() {

  const localUrl = process.env.REACT_APP_LOCAL_URL;

  const [searchValue,setSearchValue] = useState("")
  const [searchTag,setSearchTag] = useState("")
  const [auth,setAuth] = useState(true);
  const [catSec,setCatSec] = useState();
  const [resCountry,setResCountry] = useState(true);
  const [redVideos,setRedVideos] = useState("premium-vid");
  const [headerData,setHeaderData] = useState([]);
  const [tab, setTab] = useState(1);
  let [boughtVids, setBoughtVids] = useState([]);
  let [coins,setCoins] = useState(0);
  let [isCartOpen, setIsCartOpen] = useState(false);

  const resitrict = async () =>{
    const response = await axios.get("https://ipinfo.io?token=b7b877c4cb02d2");
    var country = response.data.country;

    var blockedCountries = ['TH', 'LK']; 

        if (blockedCountries.includes(country)) {
          setResCountry(false); 
        }else{
          setResCountry(true)
        }
  }

  const getCarouselData = async () =>{
    try {
      const resp = await axios.get(`${localUrl}/get_header`)
      if(resp.data){
        setHeaderData(resp.data[0])
      }else{
        window.location.href = "/networkerror"
      }
  
    } catch (error) {
      // window.location.href = "/networkerror"
      alert("Error");
    }
  }

  useEffect(()=>{
    resitrict();
    getCarouselData();
  },[])
  
 useEffect(()=>{
  const userId = localStorage.getItem("token_id");
  
  async function getCoins(){
    try {
      const response = await axios.get(`${localUrl}/get_bal?user_id=${userId}`)
      const result = response.data;
      if(!result){
        setCoins(0);
      }
      if(result === "There is no row at position 0."){
        alert("Session expired");     
        setCoins(0);
      }
      else{
        setCoins(result);
        
      }
    } catch (error) {
      console.log(error)
      // window.location.href = "/networkerror"
      // alert("Error");
      setCoins(0);
    }
 }
  if(userId !== null){
    getCoins();
    // navigate('/Login');
  }else{
    setCoins(0);
  }
 },[coins])

useEffect(()=>{
    if(localStorage.getItem("cart-vids") === null){
      
      localStorage.setItem("cart-vids",JSON.stringify(boughtVids));
    }else{
      
      setBoughtVids(JSON.parse(localStorage.getItem("cart-vids")));
    }

    if(localStorage.getItem("token_id") == null){
      setAuth(true) 
    }else{
      setAuth(false)
    }
},[localStorage.getItem("token_id"),auth])

  return (
    <>
    {resCountry ? (
      <firstProvider.Provider value={[catSec,setCatSec,redVideos,setRedVideos,headerData,setHeaderData]} >
      <contextProvider.Provider value={[searchValue,setSearchValue,searchTag,setSearchTag,auth,setAuth,tab, setTab]} >
      <BoughtVideosProvider.Provider value={{boughtVids, setBoughtVids}}>
        <CoinsProvider.Provider value={{coins, setCoins}}>
          <CartProvider.Provider value={{isCartOpen, setIsCartOpen}}>
              <BrowserRouter>
                <Routes>
                  <Route exact path='/' element={<Home/>} >
                  </Route>
                  <Route  path='/red-videos' element={<RedVideos/>} />
                  <Route exact path='/Login' element={<Login/>} />
                  <Route  path='/signup' element={<Signup/>} />
                  <Route  path='/sucess' element={<Success/>} />
                  <Route  path='/cancel' element={<Cancel/>} />
                  <Route  path='/Forgot' element={<Forgot/>} />
                  <Route path='/cart' element={<CartPage coins={coins}/>}/> 
                  {/* <Route path='/myvids' element={<MyVideos/>}/>  */}
                  <Route  path='/networkerror' element={<NetworkError/>} />
                  <Route path='*' element={<NOTFOUND/>} />
                </Routes>	
              </BrowserRouter>
            </CartProvider.Provider>
        </CoinsProvider.Provider>
      </BoughtVideosProvider.Provider>
      </contextProvider.Provider>
      </firstProvider.Provider>
    ) : 
    (
      <BrowserRouter>
    <Routes>
      <Route exact path='/' element={<NOTFOUND/>} />
      <Route path='*' element={<NOTFOUND/>} />
    </Routes>
    </BrowserRouter>
    )
    }
    
    </>

  );
}

export default App;
