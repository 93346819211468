import React ,{useContext, useEffect, useState} from 'react'
import "../assets/css/category-sub.css"
import { Link } from 'react-router-dom'
import loginimage from '../assets/img/login-left.jpg';
import { contextProvider } from '../App';

const CategorySub = () => {
  
  const [searchValue,setSearchValue,searchTag,setSearchTag,auth,setAuth] = useContext(contextProvider)
  const [getTag,setGetTag]= useState("")

  useEffect(()=>{
    setSearchTag(getTag)
  },[getTag])

  return (
    <>
       <div className="categories-sub">
        <div className="btns">
            <Link>All</Link>
            <Link>Solo</Link>
            <Link>Transgender</Link>
            <Link>Red Videos</Link>
        </div>
        <div className="categories">
            <Link>
            <img src={loginimage} alt="img" />
            <p>Squirting</p></Link>
            <Link>
            <img src={loginimage} alt="img" />
            <p>Anal</p></Link>
            <Link>
            <img src={loginimage} alt="img" />
            <p>Milf</p></Link>
            <Link>
            <img src={loginimage} alt="img" />
            <p>Milf</p></Link>
            <Link>
            <img src={loginimage} alt="img" />
            <p>Squirting</p></Link>
            <Link>
            <img src={loginimage} alt="img" />
            <p>Anal</p></Link>
            <Link>
            <img src={loginimage} alt="img" />
            <p>Milf</p></Link>
            <Link>
            <img src={loginimage} alt="img" />
            <p>Milf</p></Link>
        </div>

       </div>
    </>
  )
}

export default CategorySub