import React, { useContext, useEffect, useState } from 'react'
import { BoughtVideosProvider } from '../App'
import CartItem from '../componant/CartItem'
import "../assets/css/cart.css";
import Header from '../componant/Header';
import AuthHeader from '../auth/AuthHeader';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';

function CartPage({coins}) {
  const {boughtVids, setBoughtVids} = useContext(BoughtVideosProvider)
  const localUrl = process.env.REACT_APP_LOCAL_URL;
  const navigate = useNavigate();
  let [sum, setSum] = useState(0);
  
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const homePage = (e) =>{
    setCurrentPage(e)
  }

  useEffect(()=>{
    sum = 0;
    boughtVids.forEach(element => {
      sum += Number(element.vip_price)
    });
    setSum(sum);
  },[boughtVids])

  function removeFromCart(vidId){
    setBoughtVids((prevBoughtVids) =>
      prevBoughtVids.filter((video) => video.vidId !== vidId)
    );
  }
  
  
  async function buyVid(vidId, coins, remarks, status) {
    const userid = localStorage.getItem("token_id");
    if (userid == null) {
      navigate("/Login");
    }

    const date = new Date();
    const month = date.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month
    const day = date.getDate();
    const year = date.getFullYear();

    const todaysDate = `${year}-${month}-${day}`;
    const time = date.toLocaleTimeString();

    try {
        const response = await axios.post(`${localUrl}/buy_video?userid=${userid}&video_id=${vidId}&trancDate=${todaysDate}&trancTime=${time}&trancAmt=${coins}&status=${status}&remarks=${remarks}`);
        if(response.data === true){
          toast("Bought video");
          if(boughtVids.length === 1){
            setTimeout(()=>{
              removeFromCart(vidId)
            },6000)
            
          }else{
            removeFromCart(vidId)
          }
          
        }else if(response.data === 'YOU DONT HAVE SUFFICIENT BALANCE'){
          toast.error("YOU DONT HAVE SUFFICIENT BALANCE");
        }
        else{
          console.log(response)
        }
    } catch (error) {
        toast.error("Error")
    }
  }
  const handleBuy = ()=>{
    const userid = localStorage.getItem("token_id");
    if (userid == null) {
        navigate("/Login");
    }
    boughtVids.forEach(vid=>{
      const vidId = vid.video_id;
      const coins = vid.vip_price;
      const status = `${vidId}bought`;
      const remarks = `v-${vidId}`;
      buyVid(vidId,10,remarks,status);
    })
  }
  return (
    <>
    <AuthHeader/>
    <ToastContainer/>
    <div className='cart-container'>
     
      {/* <Header searchTerm={searchTerm} homePage={homePage} hideHeader={true} hideNavMenu={false}/> */}
      <h1 className='cart-heading'>YOUR CART</h1>
      {/* <button onClick={()=>{
        console.log(boughtVids)
      }}>click me</button> */}
     
      <div className='cart-items'>
      {boughtVids.map(item=>{
        return <CartItem 
          
          title={item.title}
          imgUrl = {item.poster}
          vidId = {item.video_id}
          coins = {item.vip_price}
          boughtVids={boughtVids}
          setBoughtVids={setBoughtVids}
          video_desc={item.video_desc}
          key={item.rowid}
          ></CartItem>

      })}
      </div>
      <div className='cart-page-info'>
      {sum > coins ? 
      <>You don't have enough balance please add coins</>
      :
      <>
        <button onClick={handleBuy}>Buy All</button>
      </>}
      </div>
    </div>
    </>
  )
}

export default CartPage