import React from 'react'
import "../assets/css/footer.css"

const Footer = () => {
  return (
    <>
    <footer className="footer"><h4>© 2023 LUSTRAP •</h4></footer>
    </>
  )
}

export default Footer