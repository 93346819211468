import React, { useState, useEffect, useRef } from "react";
import image10 from "../assets/img/whatsapp-logo-24.png"
import "../assets/css/button.css";

const Button = () => {
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleClickOutside = (event) => {
    if (
      popupRef.current &&
      !popupRef.current.contains(event.target) &&
      event.target.classList.contains("popup-overlay")
    ) {
      setShowPopup(false);
    }
  };

  return (
    <React.Fragment>
      <div className="btn">
        <button className="sticky-button" onClick={togglePopup}>
          How to get My Videos!
        </button>

        <img src={image10} onClick={togglePopup}></img>

        {showPopup && (
          <div className="popup-overlay" onClick={handleClickOutside}>
            <div className="popup" ref={popupRef} >
            <div className="close-popup"  onClick={togglePopup} ></div>
              <div className="popup-content">
                <h1>HOW TO GET MY VIDEOS!</h1>
                <h2>
                Click the link below the video,this will drop you into my direct messages on OnlyFans. 
                Send me the video number and I will answer you as soon as possible! 💋💕                </h2>
              <span className="close-icon" onClick={togglePopup}>
              <svg  version="1.1"  width="40px" height="40px" x="0" y="0" viewBox="0 0 24 24" ><g><path d="M12 1a11 11 0 1 0 11 11A11.013 11.013 0 0 0 12 1zm4.242 13.829a1 1 0 1 1-1.414 1.414L12 13.414l-2.828 2.829a1 1 0 0 1-1.414-1.414L10.586 12 7.758 9.171a1 1 0 1 1 1.414-1.414L12 10.586l2.828-2.829a1 1 0 1 1 1.414 1.414L13.414 12z" data-name="Layer 2" fill="#000000" data-original="#000000" ></path></g></svg>
              </span>
              </div>
            </div>
          </div>
        )}
        
      </div>
    </React.Fragment>
  );
};

export default Button;