import React,{useState,useEffect, useRef} from 'react'
import "../assets/css/success.css"
import { Link } from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';

const Success = () => {
  const [userid , setUserid ] = useState(0);
  const [coins, setCoins ] = useState(0);

  const handleSubmit = async ()=>{
    const todaysDate = new Date();
    const month = todaysDate.getMonth() + 1 ;// 1
    const date = todaysDate.getDate();//26
    const year = todaysDate.getFullYear();

    const dateToSend = `${month}-${date}-${year}`;
    // console.log(tranType, crdt_coin, userid, remarks, dateToSend)
    console.log(userid, coins)
    try{
      const response =await axios.post(`http://test-maylee.hinalvakharia.com/api/account_edit?tranType=cred&trans_date=${dateToSend}&trans_time=${todaysDate.toLocaleTimeString()}&crdt_coin=${coins}&dbt_coin=0&remarks=credited${coins}&userid=${userid}`);
      console.log(response);
      if(response.data === true){
        //toast 
        alert("Succesfully Credited");  
      }else{
        // alert("Failed")
        console.log(response.data)
      }
    }catch(err){
      // alert("Failed to credit");
      console.log(err)
      
    }
    
  }

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const coin = queryParameters.get("coins")
    const userId = queryParameters.get("userId")
    const uerIdToSet = CryptoJS.AES.decrypt(userId, process.env.REACT_APP_SECRET).toString(CryptoJS.enc.Utf8)
    setUserid(uerIdToSet);
    setCoins(coin);
    handleSubmit();

  }, [userid,coins]);


  const videoEl = useRef(null);
  const [videoSource, setVideoSource] = useState(null);


    const videoBlob = async () =>{
     await fetch("https://stream.mux.com/6fiGM5ChLz8T66ZZiuzk1KZuIKX8zJz00/medium.mp4")
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], 'video', {type:blob.type})
        readFile(file);
      })
    }
  
    function readFile(input) {
      const fr = new FileReader();
      fr.readAsDataURL(input);
  
      fr.addEventListener("load",()=>{
        const res = fr.result;
        console.log(res);
        setVideoSource(res);
      })
    }
  
  
    useEffect(()=>{
      videoBlob()
        },[])



    const attemptPlay = () => {
      videoEl &&
        videoEl.current &&
        videoEl.current.play().catch(error => {
          console.error("Error attempting to play", error);
        });
    };
  
    useEffect(() => {
      attemptPlay();
    }, []);

  return (
    <>
    <section className='success-page' >
    <Link to="/"  className='logo'>
      <svg xmlns="http://www.w3.org/2000/svg" width="113" height="28" viewBox="0 0 113 28">
  <g id="Group_1" data-name="Group 1" transform="translate(-45 -26)">
    <path id="Path_1" data-name="Path 1" d="M5,0H47a5,5,0,0,1,5,5V23a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z" transform="translate(106 26)" fill="#f8981d"/>
    <text id="Malee-text" transform="translate(45 47)" fill="#fff" fontSize="20"  fontWeight="700" letterSpacing="0.045em"><tspan x="0" y="0">Malee</tspan></text>
    <text id="Fun-text" transform="translate(113 47)" fontSize="20"  fontWeight="700" letterSpacing="0.045em"><tspan x="0" y="0">Fun</tspan></text>
  </g>
</svg>
        </Link>
        <h1>Your Payment Is Successful</h1>
        <div className="video-section">
        <video
          playsInline
          loop
          muted
          controls
          controlsList="nodownload"
          alt="All the devices"
          src={videoSource}
          ref={videoEl}
        />
        </div>
    </section>
    </>
  )
}

export default Success