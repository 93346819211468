import React from "react";

const Radio = ({ name, value, onChange,defaultChecked }) => {

  
  return (
    <input
      type="radio"
      name={name}
      value={value}
      onChange={onChange}
      defaultChecked={defaultChecked}
    />
  );
};

export default Radio;