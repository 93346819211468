import React, { useContext, useState } from 'react'
import { BoughtVideosProvider } from '../App'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

function CartItem({title,imgUrl,vidId,coins,boughtVids, setBoughtVids, video_desc, fromCart}) {
  const [showFullDescription, setFullDescription] = useState(false);
  const status = `${vidId}bought`;
  const remarks = `v-${vidId}`;

  const localUrl = process.env.REACT_APP_LOCAL_URL;

  const showFullDescriptionHandler = () => {
    // console.log(video_desc);
    setFullDescription(!showFullDescription);
  };   

  const description = showFullDescription
    ? video_desc
    : video_desc.slice(0, 80);

  function removeFromCart(id){ 
    setBoughtVids((boughtVids) =>
    boughtVids.filter((video) => video.video_id != vidId)
    );
  }

  async function buyVid(vidId, coins, remarks, status) {
    const userid = localStorage.getItem("token_id");
    if (userid == null) {
        
        return; // Exit function if user is not authorized
    }

    const date = new Date();
    const month = date.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month
    const day = date.getDate();
    const year = date.getFullYear();

    const todaysDate = `${year}-${month}-${day}`;
    const time = date.toLocaleTimeString();

    try {
        const response = await axios.post(`${localUrl}/buy_video?userid=${userid}&video_id=${vidId}&trancDate=${todaysDate}&trancTime=${time}&trancAmt=${coins}&status=${status}&remarks=${remarks}`);
        if(response.data === true){
          toast("Bought video");
          if(boughtVids.length === 1){
            setTimeout(()=>{
              removeFromCart(vidId)
            },6000)
            
          }else{
            removeFromCart(vidId)
          }
          
        }else if(response.data === 'YOU DONT HAVE SUFFICIENT BALANCE'){
          toast.error("YOU DONT HAVE SUFFICIENT BALANCE");
        }
        else{
          toast.error(response);
        }
    } catch (error) {
        toast.error("Error")
    }
}

  return (
    <>
     {/* <ToastContainer/> */}
    <div className='cart-item'>
        <div className='cart-img'>
          <img src={imgUrl}></img>
        </div>
        <div className='cart-item-desc'>
          <p className='item-title'>{title}</p>
          <p className='cart-item-coins'>{coins} COINS</p>
          <p className='cart-itme-id'>Video  Id : {vidId}</p>
          {fromCart === true ? 
          <></>
          :
          <p className='vid-desc'>
              {description}{" "}
              <span
                onClick={showFullDescriptionHandler}
                className="readmoreless"
              >
                Read {showFullDescription ? "Less" : "More"}
              </span>{" "}
            </p>}
          
          <button className='item-remove-btn' onClick={()=>removeFromCart(vidId)}>Remove From Cart</button>
          {fromCart === true ? 
          <></>
          :
          <button className='item-buy-btn' onClick={()=>buyVid(vidId, coins, remarks, status)}>Buy Video</button>}
        </div>
      </div>

    </>
  )
}

export default CartItem
