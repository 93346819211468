import React,{useState,useEffect} from "react";
import '../assets/css/video.css'

const Video = ({vidUrl,hideVideo}) => {

  return (
    <React.Fragment>
    <div className="card-video"  >
    <div className="close-vid-bg" onClick={()=>{hideVideo(false)}} ></div>
    <div className="close-button" onClick={() => hideVideo(false)}>X</div>
      <video autoPlay controls playsInline disablePictureInPicture controlsList="nodownload noplaybackrate" >
        <source src={vidUrl} type="video/mp4" />
      </video>
    </div>
    </React.Fragment>
  );
};

export default Video;
