import React, { useState, useEffect, useContext } from 'react';
import '../assets/css/carousel.css';
// import image1 from '../assets/img/carousel.png';
import image2 from '../assets/img/carousel-image-2.png';
import axios from 'axios';
import { firstProvider } from '../App';

const Carousel = () => {

  const [catSec,setCatSec,redVideos,setRedVideos,headerData,setHeaderData] = useContext(firstProvider)

  // const image1 = `https://d2aq2s4fglfv4y.cloudfront.net/maylee_car_01.jpg`;
  // const image2 = `https://d2aq2s4fglfv4y.cloudfront.net/maylee_car_02.jpg`;
  // const image3 = `https://d2aq2s4fglfv4y.cloudfront.net/maylee_car_03.jpg`;

  const image1 = `https://d2aq2s4fglfv4y.cloudfront.net/${headerData.web_car_01}` 
  const image2 = `https://d2aq2s4fglfv4y.cloudfront.net/${headerData.web_car_02}`
  const image3 = `https://d2aq2s4fglfv4y.cloudfront.net/${headerData.web_car_03}`

  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [image1, image2, image3];

  const handleNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      handleNextSlide();
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <React.Fragment>
     
      <div className="carousel">
        <div className="carousel-details">
          <div className="carousel-offer">
            <p>POP OFF FOR </p>
            <h2>{headerData.car_text}</h2>
            <a className='btnlink' href={headerData.video_url} target='_blank'>
            <button className="carousel-btn">
              <span>$ {headerData.btn_price_txt}</span>!
              <p>{headerData.btn_span_txt}</p>
            </button>
            </a>
          </div>
          <div className="carousel-dots">
            {images.map((image, index) => (
              <span
                key={index}
                className={`dot ${currentIndex === index ? 'active' : ''}`}
                onClick={() => handleDotClick(index)}
              ></span>
            ))}
          </div>
          <div className="carousel-title"><h2>MAYLEE FUN</h2></div>
        </div>
        <img
          className="carousel-image"
          src={images[currentIndex]}
          alt={`Image ${currentIndex + 1}`}
        />
      </div>
    </React.Fragment>
  );
};

export default Carousel;
