import React, { useContext, useEffect, useState } from "react";

import '../assets/css/header.css';
import img4 from "../assets/img/Group 41.svg";
import img5 from "../assets/img/profile.png";
import img6 from "../assets/img/profile-icon.svg";
import img8 from "../assets/img/search (2).svg";
import img9 from "../assets/img/mobile-logo.svg";
import { Link } from "react-router-dom";
import { contextProvider ,firstProvider } from "../App";
import Marqee from "./Marqee";
import axios from "axios";
import Radio from "./Radio";
import CartItem from "./CartItem";
import { Navigate, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';

import { Oval } from 'react-loader-spinner'
import { ToastContainer, toast } from "react-toastify";
import LogoutPopUp from "./LogoutPopUp";

const Header = ({ searchTerm, hideHeader, hideNavMenu, boughtVids,setBoughtVids, coins, setCoins, isCartOpen, setIsCartOpen}) => {

  const [searchValue,setSearchValue,searchTag,setSearchTag,auth,setAuth, tab, setTab] = useContext(contextProvider)
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  let [sum, setSum] = useState(0);
  const navigate = useNavigate();
  // const [isCartOpen, setIsCartOpen] = useState(false);
  const [catSec,setCatSec,redVideos,setRedVideos]= useContext(firstProvider);
  const [tagList,setTagList] = useState([])
  const [isInProcess, setIsInProcess] = useState(false);
  const [openPopUp, setopenPopUP] = useState(false);
  const [buyvideoPopUp, setBuyVideoPopUp] = useState(false);
  const [noVidPopUp, setNoVidPopUp] = useState(false);
  const [notAuthorised, setNotAuthorised] = useState(false);
  const [notEnoughMoney, setNotEnoughMoney] = useState(false);
  const [vidsBoughtNotification, setVidsBoughtNotification] = useState(false);
  const [vidsMsg, setVidsMsg] = useState('');
  const [vidCount , setVidCount] = useState(0);
  const localUrl = process.env.REACT_APP_LOCAL_URL;

  const [cat, setCat] = useState("")

  useEffect(()=>{
    setSearchTag(cat)
  },[cat])

  const getDynamic = async () =>{
    const response = await axios.get(`${localUrl}/getTags`)
    setTagList(response.data);
  }

  useEffect(()=>{
    getDynamic()
  },[])

  const handleMenuToggle = () => {
    setIsMenuOpen((prevState) => !prevState);
  };
  const handleCartToggle = ()=>{
    setIsCartOpen((prevState) => !prevState);
  }
  
  function logout(){

      localStorage.clear("name");
      localStorage.clear("token_id");
      setBoughtVids([]);
      localStorage.setItem("cart-vids", JSON.stringify(boughtVids));
      setAuth(true)
      window.location.reload()
  }

  useEffect(() => {
    const calculatedSum = boughtVids.reduce((accumulator, element) => {
      return accumulator + Number(element.vip_price);
    }, 0);
  
    // Check if the calculated sum is different before updating the state
    const formattedSum = calculatedSum.toFixed(2);
    if (formattedSum !== sum) {
      setSum(formattedSum);
    }
  }, [boughtVids, sum]);


  function removeFromCart(vidId){
      
      setBoughtVids((prevBoughtVids) =>
        prevBoughtVids.filter((video) =>video.video_id != vidId)
      );
  }

  async function buyVid(vidId, coins, remarks, status, rowid, succesfullVids, notSuccesfullVids) {
      const userid = localStorage.getItem("token_id");
      if (userid == null) {
        navigate("/Login");
    }
  
      const date = new Date();
      const month = date.getMonth() + 1; 
      const day = date.getDate();
      const year = date.getFullYear();
  
      const todaysDate = `${year}-${month}-${day}`;
      const time = date.toLocaleTimeString();

      try {
          const response = await axios.post(`${localUrl}/buy_video?userid=${userid}&video_id=${vidId}&trancDate=${todaysDate}&trancTime=${time}&trancAmt=${coins}&status=${status}&remarks=${remarks}&ref_rowid=${rowid}`);
          if(response.data === true){
            succesfullVids.push(vidId);
            setVidCount((p)=> p+1);
            removeFromCart(vidId);
          }else if(response.data === "USER DO NOT EXITS"){
            alert("Login")
            navigate("/Login")
          }
          else{
            notSuccesfullVids.push(vidId);
            // alert("Not able to buy this video")
            // console.log(response);
          }
      } catch (error) {
        notSuccesfullVids.push(vidId);
        // alert("Error while buying vid with id " + vidId);
      }
    }
  
    const handleBuy = async () => {
      const userid = localStorage.getItem("token_id");
    
      if (boughtVids.length === 0) {
        setNoVidPopUp(true);
        return;
      } else if (userid == null) {
        setNotAuthorised(true);
        return;
      } else if (Number.parseFloat(sum) > Number.parseFloat(coins)) {
        setNotEnoughMoney(true);
        return;
      }
    
      setIsInProcess(true);
      let succesfullVids = [];
      let notSuccesfullVids = [];
      debugger
      for (const vid of boughtVids) {
        const vidId = vid.video_id;
        const coins = vid.vip_price;
        const status = `${vidId}bought`;
        const remarks = `v-${vidId}`;
        const rowId = vid.rowid;
        
        // Assuming buyVid is an asynchronous function (returns a Promise)
        await buyVid(vidId, coins, remarks, status, rowId, succesfullVids, notSuccesfullVids);
      }
      
      if(succesfullVids.length === boughtVids.length){
        setVidsMsg(`Succesfull purchased  ${succesfullVids.length} video(s)`);
        setVidsBoughtNotification(true)
      }else{
        setVidsMsg("There was problem while buying some videos try again for those specific videos");
        setVidsBoughtNotification(true)
      }
      setIsInProcess(false);
      succesfullVids = [];
      notSuccesfullVids = [];

    };


    useEffect(() => {
      // Check and set "cart-vids" in localStorage if needed
      if (localStorage.getItem("cart-vids") === null) {
        localStorage.setItem("cart-vids", JSON.stringify(boughtVids));
      }
    
      // Check and set authentication based on "token_id"
      if (localStorage.getItem("token_id") == null) {
        setAuth(true);
      } else {
        setAuth(false);
      }
    }, []); // Empty dependency array ensures this useEffect runs only on mount
    
    // Update boughtVids separately when needed
    useEffect(() => {
      if (localStorage.getItem("cart-vids") !== null) {
        setBoughtVids(JSON.parse(localStorage.getItem("cart-vids")));
      }
    }, []);
    
    
 useEffect(()=>{
  const userId = localStorage.getItem("token_id");
  
  async function getCoins(){
    try {
      const response = await axios.get(`${localUrl}/get_bal?user_id=${userId}`)
      const result = response.data;
      if(!result){
        setCoins(0);
      }
      if(result === "There is no row at position 0."){
        alert("Session expired");     
        setCoins(0);
      }
      else{
        setCoins(result);
        
      }
    } catch (error) {
      // console.log(error)
      alert("Not able to fetch coins")
    }
 }
  if(userId !== null){
    getCoins();
    // navigate('/Login');
  }else{
    setCoins(0);
  }
 },[boughtVids])


  return (
    <React.Fragment>
       
            <Marqee/>
         
      <div className= 'header'>
      {openPopUp && 
          <div className="logout-pop-up">
            <div className="pop-up-em" onClick={()=>setopenPopUP(false)}></div>
            <div className="logout-pop-up-content">
              <div className="logout-confirmation">
                <p>Do you really Want to logout? </p>
              </div>
              <div className="logout-confirmation-btns">
                <span className="cancel-logout" onClick={()=>setopenPopUP(false)}>No</span>
                <span className="approve-logout" onClick={()=>logout()}>Yes</span>
              </div>
            </div>
          </div>
        }
        {buyvideoPopUp && 
          <div className="logout-pop-up">
            <div className="pop-up-em" onClick={()=>setBuyVideoPopUp(false)}></div>
            <div className="logout-pop-up-content">
              <div className="logout-confirmation">
                <p>Confirm buying videos..</p>
              </div>
              <div className="logout-confirmation-btns">
                <span className="cancel-logout" onClick={()=>setBuyVideoPopUp(false)}>No</span>
                <span className="approve-logout" onClick={()=>handleBuy()}>Yes I am buying</span>
              </div>
            </div>
          </div>
        }
        {noVidPopUp && 
          <div className="logout-pop-up">
            <div className="pop-up-em" onClick={()=>setNoVidPopUp(false)}></div>
            <div className="logout-pop-up-content">
              <div className="logout-confirmation">
                <p>Please add videos to continue..</p>
              </div>
              <div className="logout-confirmation-btns">
                {/* <span className="cancel-logout" onClick={()=>setBuyVideoPopUp(false)}>No</span> */}
                <span className="approve-logout" onClick={()=>setNoVidPopUp(false)}>Close</span>
              </div>
            </div>
          </div>
        }
         {notAuthorised && 
          <div className="logout-pop-up">
            <div className="pop-up-em" onClick={()=>setNotAuthorised(false)}></div>
            <div className="logout-pop-up-content">
              <div className="logout-confirmation">
                <p>You are not logged in, login to continue buying videos...</p>
              </div>
              <div className="logout-confirmation-btns">
                <span className="cancel-logout" onClick={()=>navigate('/Login')}>Login</span>
                <span className="approve-logout" onClick={()=>setNotAuthorised(false)}>Close</span>
              </div>
            </div>
          </div>
        }
        {notEnoughMoney && 
          <div className="logout-pop-up">
            <div className="pop-up-em" onClick={()=>setNotEnoughMoney(false)}></div>
            <div className="logout-pop-up-content">
              <div className="logout-confirmation">
                <p>You don't have enough coins, please add coins to buy videos.</p>
              </div>
              <div className="logout-confirmation-btns">
                {/* <span className="cancel-logout" onClick={()=>navigate('/Login')}>Login</span> */}
                <span className="approve-logout" onClick={()=>setNotEnoughMoney(false)}>Close</span>
              </div>
            </div>
          </div>
        }
        {vidsBoughtNotification && 
          <div className="logout-pop-up">
          <div className="pop-up-em" onClick={()=>setVidsBoughtNotification(false)}></div>
          <div className="logout-pop-up-content">
            <div className="logout-confirmation">
              <p>{vidsMsg}</p>
            </div>
            <div className="logout-confirmation-btns">
              {/* <span className="cancel-logout" onClick={()=>navigate('/Login')}>Login</span> */}
              <span className="approve-logout" onClick={()=>setVidsBoughtNotification(false)}>Close</span>
            </div>
          </div>
        </div>

        }
         {/* {isInProcess && 
          <div className="logout-pop-up">
            <div className="pop-up-em"></div>
            <div className="logout-pop-up-content">
              <div className="logout-confirmation">
                <p>We are processing your request hold on...</p>
              </div>
              <div className="logout-confirmation-btns">
                <span className="cancel-logout" onClick={()=>navigate('/Login')}>Login</span>
                <span className="approve-logout" onClick={()=>setNotEnoughMoney(false)}>Close</span>
              </div>
            </div>
          </div>
        } */}
      <div className={isMenuOpen ? "drawer open" : "drawer" }>
          <ul>
          {!auth && <li className="name-coins"><><Link>Hii,<span>{localStorage.getItem("name")}</span></Link></> <span><span className="avl">AVAIL BAL. : </span>   {parseFloat(coins).toFixed(2)}</span></li>}
            {!auth && <li><Link target="_blank" to={`http://maylee-stripe-client.s3-website.us-east-2.amazonaws.com?userId=${localStorage.getItem("token_id")}`}>Add Coins</Link></li>}
            {!auth && <li><Link target="_blank" >My Account</Link></li>}
            {/* <li><Link onClick={(e)=>{setRedVideos("my-vid")}}>{auth?"":"My Videos"}</Link></li> */}
            <li><Link to={auth ? "/login" : "/"}  onClick={()=>!auth && setopenPopUP(true)}>{auth?"login":"logout"}</Link></li>

            {auth && <li><Link to={"/Signup"} >register</Link></li>}
           
          </ul>           
        </div>
        <div className={isCartOpen ? "drawer open" : "drawer" } id="cartPopUp">
          
          
          <span className="cart-close-btn"  onClick={handleCartToggle}><span className="cart-page">YOUR CART</span> <span>X</span></span>
          <span className="name-and-coins"><p className="name">Hi, {localStorage.getItem("name")}</p><p className="coins">Avail Bal. &nbsp; {parseFloat(coins).toFixed(2)}</p></span>
          
          <div className="cart-item-container">
          {boughtVids.map(item=>{

            return <CartItem
              
              title={item.title}
              imgUrl = {item.poster}
              vidId = {item.video_id}
              coins = {item.vip_price}
              boughtVids={boughtVids}
              setBoughtVids={setBoughtVids}
              key={item.rowid}
              video_desc={item.video_desc}
              fromCart = {true}
              
              ></CartItem>
            })}
          </div>
          
        <div className="cart-last">
              <div className="est-total">
                <p className="est-total-top"><span>Estimated Total</span><span>Total: {sum}</span></p>
                <p className="est-total-bottom">ALL SALES ARE FINAL. NO REFUND. For concerns, kindly email me at 
 <a href="mailto:fun69work@gmail.com">fun69work@gmail.com</a>
                  </p>
              </div>
              <button onClick={()=>handleBuy()} className="chekout-btn">
                {isInProcess ? (<>Processing...</>) : (<>Checkout</>)}
              </button>
          </div>
        </div>
        <div className="tags mobile "># 
         <ul>
          <li onClick={() =>{setCat("")}}  >All</li>
          {tagList.map((items)=>(
            <li key={items.tags} onClick={() =>{setCat(items.tags)}}  >
              {items.tags}
            </li>
          ))}
         </ul>
         </div>
        <Link to="/"  className='logo'>
          {/* <img onDragStart={event => event.preventDefault()} className="image-1" src={img4} alt="logo" /> */}
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="113" height="28" viewBox="0 0 113 28">
  <g id="Group_1" data-name="Group 1" transform="translate(-45 -26)">
    <path id="Path_1" data-name="Path 1" d="M5,0H47a5,5,0,0,1,5,5V23a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z" transform="translate(106 26)" fill="#f8981d"/>
    <text id="Malee-text" transform="translate(45 47)" fill="#fff" fontSize="20"  fontWeight="700" letterSpacing="0.045em"><tspan x="0" y="0">Malee</tspan></text>
    <text id="Fun-text" transform="translate(113 47)" fontSize="20"  fontWeight="700" letterSpacing="0.045em"><tspan x="0" y="0">Fun</tspan></text>
  </g>
</svg> */}

<svg xmlns="http://www.w3.org/2000/svg" width="128.68" height="28" viewBox="0 0 128.68 28">
  <g id="Group_126" data-name="Group 126" transform="translate(18444.68 920)">
    <g id="Group_125" data-name="Group 125" transform="translate(-18474 -946)">
      <path id="Path_1" data-name="Path 1" d="M5,0H47a5,5,0,0,1,5,5V23a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z" transform="translate(106 26)" fill="#f8981d"/>
      <path id="Path_67" data-name="Path 67" d="M15.78,0V-13.34h-4L8.54-5.02,5.3-13.34H1.32V0H4.16V-9.6L7.92,0H9.16l3.76-9.6V0ZM27.6,0V-6.24c0-2.78-2.02-3.66-4.22-3.66a6.337,6.337,0,0,0-4.22,1.52l.96,1.7a4.065,4.065,0,0,1,2.82-1.14c1.28,0,2.12.64,2.12,1.62v1.34A3.9,3.9,0,0,0,22-6.04a3.059,3.059,0,0,0-3.36,3.08A3.218,3.218,0,0,0,22,.24,4,4,0,0,0,25.06-1V0ZM25.06-2.32a2.541,2.541,0,0,1-2.04.84c-1,0-1.82-.52-1.82-1.42,0-.92.82-1.46,1.82-1.46a2.541,2.541,0,0,1,2.04.84Zm5.62,6.1a6.943,6.943,0,0,0,1.26.14A3.51,3.51,0,0,0,35.6,1.58L40.14-9.66H37.4L34.88-2.94,32.36-9.66H29.64L33.56.14,33.2.96a1.281,1.281,0,0,1-1.34.7,2.195,2.195,0,0,1-.8-.16ZM44.72,0V-13.34H42.18V0Zm2.8-4.84A4.892,4.892,0,0,0,52.68.24,5.954,5.954,0,0,0,56.66-1.1L55.54-2.74a3.95,3.95,0,0,1-2.58.96,2.6,2.6,0,0,1-2.78-2.24h7.18v-.56c0-3.16-1.96-5.32-4.84-5.32A4.916,4.916,0,0,0,47.52-4.84Zm5-3.04A2.238,2.238,0,0,1,54.9-5.74H50.14A2.321,2.321,0,0,1,52.52-7.88Zm6.96,3.04A4.892,4.892,0,0,0,64.64.24,5.953,5.953,0,0,0,68.62-1.1L67.5-2.74a3.95,3.95,0,0,1-2.58.96,2.6,2.6,0,0,1-2.78-2.24h7.18v-.56c0-3.16-1.96-5.32-4.84-5.32A4.916,4.916,0,0,0,59.48-4.84Zm5-3.04a2.238,2.238,0,0,1,2.38,2.14H62.1A2.321,2.321,0,0,1,64.48-7.88Z" transform="translate(28 47)" fill="#fff"/>
      <path id="Path_68" data-name="Path 68" d="M4.16,0V-5.56h6.46V-8H4.16v-2.9h6.6v-2.44H1.32V0ZM22.64,0V-9.66H20.1v6.54a2.843,2.843,0,0,1-2.2,1.1A1.553,1.553,0,0,1,16.12-3.8V-9.66H13.58V-2.8A2.766,2.766,0,0,0,16.7.24a4.521,4.521,0,0,0,3.4-1.46V0ZM35.12,0V-6.82A2.81,2.81,0,0,0,31.98-9.9,4.423,4.423,0,0,0,28.6-8.4V-9.66H26.06V0H28.6V-6.5a2.751,2.751,0,0,1,2.2-1.14,1.567,1.567,0,0,1,1.78,1.8V0Z" transform="translate(113 47)"/>
    </g>
  </g>
</svg>

        </Link>
        <div className="search-bar">
          {hideHeader && <input
            type="text"
            placeholder="search for video title, category"
            value={searchValue}
            id={searchTerm}
            onChange={(e)=>{setSearchValue(e.target.value)}}
          />}
          <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
        </div>
        <div className="right">
        <span className="icon">
  {/* this is cart drawer   to='/cart'  */} 
        <Link onClick={handleCartToggle}>
        <svg xmlns="http://www.w3.org/2000/svg" width="36.825" height="30.688" viewBox="0 0 36.825 30.688">
  <g id="cart_mf" transform="translate(0 -4)">
    <path id="Path_3" data-name="Path 3" d="M29.787,27.016H15.359a3.833,3.833,0,0,1-3.684-2.786L6.8,7.2a2.3,2.3,0,0,0-2.21-1.669H.767A.767.767,0,1,1,.767,4H4.588A3.832,3.832,0,0,1,8.272,6.786l.962,3.351H34.523a2.3,2.3,0,0,1,2.2,2.961l-3.3,11.278A3.83,3.83,0,0,1,29.787,27.016ZM9.673,11.672l3.478,12.14a2.293,2.293,0,0,0,2.208,1.669H29.787A2.317,2.317,0,0,0,31.96,23.92l3.3-11.259a.768.768,0,0,0-.735-.988Zm7.206,23.016a3.069,3.069,0,1,1,3.069-3.069A3.072,3.072,0,0,1,16.878,34.688Zm0-4.6a1.536,1.536,0,1,0,1.467,1.534A1.536,1.536,0,0,0,16.878,30.085Zm10.741,4.6a3.069,3.069,0,1,1,3.069-3.069A3.072,3.072,0,0,1,27.619,34.688Zm0-4.6a1.536,1.536,0,1,0,1.467,1.534,1.536,1.536,0,0,0-1.467-1.534Z" fill="#f8981d"/>
  </g>
</svg>
<span>{boughtVids.length}</span>
        </Link>
</span>
        <span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width="28.559" height="32.87" viewBox="0 0 28.559 32.87">
  <g id="account_mf" transform="translate(-2.66 -1.25)">
    <g id="Group_2" data-name="Group 2" transform="translate(2.66 1.25)">
      <path id="Path_2" data-name="Path 2" d="M16.939,18.832a8.791,8.791,0,1,1,8.791-8.791A8.8,8.8,0,0,1,16.939,18.832Zm0-15.288a6.5,6.5,0,1,0,6.5,6.5A6.512,6.512,0,0,0,16.939,3.543ZM30.072,34.12a1.155,1.155,0,0,1-1.147-1.147c0-5.274-5.382-9.555-11.986-9.555S4.953,27.7,4.953,32.973a1.147,1.147,0,1,1-2.293,0c0-6.528,6.406-11.848,14.279-11.848s14.279,5.32,14.279,11.848A1.155,1.155,0,0,1,30.072,34.12Z" transform="translate(-2.66 -1.25)" fill="#f8981d"/>
    </g>
  </g>
</svg>
{!auth && <p className="user-info" ><>Hi,{localStorage.getItem("name")}</></p>}
<ul className="user-register"> 
<li><Link to={auth ? "/login" : "/"} className="login-btn" onClick={()=>!auth && setopenPopUP(true)}>
  {auth?"login":"logout"}
  </Link></li>

{auth && <li><Link to={"/Signup"} className="login-btn">register</Link></li>}
</ul>
</span>
<div className={`burger-menu ${isMenuOpen ? 'active' : '' }`}>
        <div className="b-menu"   onClick={handleMenuToggle}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        </div>
<div>
      
      </div>

      </div>
       {hideNavMenu && 
       <div className="header-navmenu">
       <ul>

         <li onClick={()=>{setCatSec(false); setRedVideos("premium-vid"); setTab(1) }} > <Radio name="cat" value="" onChange={() => setCat("")} defaultChecked={!catSec } />PREMIUM  VIDEOS<b></b> </li>
         <li onClick={()=>{setCatSec(false); setRedVideos("quickies-vid"); setTab(2) }} > <Radio name="cat" value="" onChange={() => setCat("")} defaultChecked={false} />QUICKIES <b></b> </li>
         <li onClick={()=>{setRedVideos("exclusive-vid")}} ><Radio name="cat" value="" onChange={() => {setCat("")}} defaultChecked={false} /> <span>EXCLUSIVE</span> VIDEOS <b></b>  </li>
         <li onClick={()=>{setRedVideos("my-vid")}} ><Radio name="cat" value="" onChange={() => {setCat("")}} defaultChecked={false} />MY VIDEOS <b></b>  </li>
         <li onClick={() => {window.open("https://onlyfans.com/my/chats/chat/130013409/?q=mayleefun", "_blank")}} >  ONLY FANS <b></b> </li>
         <li onClick={() => {window.open("https://t.me/+Is-pmutIn8RkMjAx","_blank")}}>   TELEGRAM <b></b> </li>
        {/* <li onClick={()=>{setCatSec(true)}} ><Radio name="cat" value="categories" onChange={() => setCat("")} defaultChecked={catSec} /> CATEGORIES <b></b></li>        */}
        {/* <li onClick={()=>{setCatSec(false)}} >  <Radio name="cat" value="photos" onChange={() => setCat("photos")} defaultChecked={false} /> PHOTOS <b></b> </li> */}
        {/* <li onClick={()=>{setCatSec(false); setRedVideos(false); setTab(1) }} > <Radio name="cat" value={""} onChange={() => setCat("")} defaultChecked={!catSec} />PREMIUM  VIDEOS<b></b> </li> */}

       </ul>
      </div>}
      </div>
    </React.Fragment>
  );
};

export default Header;
