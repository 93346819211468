import React, { useState ,useContext } from 'react';
import './auth.css';
import { Link ,useNavigate } from 'react-router-dom';
import { contextProvider } from '../App';
import { ToastContainer, toast } from 'react-toastify';
import AuthHeader from './AuthHeader';
import { Oval } from 'react-loader-spinner'
import axios from 'axios';

function Signup() {

  const [searchValue,setSearchValue,searchTag,setSearchTag,auth,setAuth] = useContext(contextProvider)
  const navigate = useNavigate();
  const [loader,setLoader] = useState(false)
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error,setError] = useState()
  const [error1, setError1] = useState(false);
  const [isUsernameTaken, setIsUsernameTaken] = useState(false);

  const localUrl = process.env.REACT_APP_LOCAL_URL;

  const validUsername = new RegExp('^[a-z][^\W_]{3,14}$');
  const validEmail = new RegExp('^[a-z0-9._%+-]+@[a-z0-9]+[.]+[a-z]{2,4}$');
  const validPassword = new RegExp('^(?=.*[A-Z])(?!.*[^a-zA-Z0-9@#$^+=])(.{8,15})$');

  const handleChangeUser = (event) => {
    setIsUsernameTaken(false);

    const value = event.target.value;
    if (!value.replace(/[^\`\']/gi, '')) {
      setUsername(value);
    }
  };
  const handleChangeEmail = (event) => {
    const value = event.target.value;
    if (!value.replace(/[^\`\']/gi, '')) {
      setEmail(value)
    }
  };
  const handleChangePass = (event) => {
    const value = event.target.value;
    if (!value.replace(/[^\`\']/gi, '')) {
      setPassword(value)
    }
  };

  const submitHandler = async (e) => {
  
    e.preventDefault();
    if (!validUsername.test(username)) {
      setError("Username contain letter between 4 to 14 ")
    }else if(!validEmail.test(email)){
      setError("Email contain @ then . domain")
    }else if(!validPassword.test(password)){
      setError("Password contain 1 Capital,1 Small, 1 Numeric num , 1 symbol and between 8 to 15 letter")
    } else{
      setError(null)
      try {

        const response = await axios.post(`${localUrl}/account_register?username=${username}&password=${password}&email_id=${email}`);
        let data = response.data;
        
        if(typeof data === 'object'){
          
          let token_id = data[1];
          let username = data[2];
          localStorage.setItem("token_id",token_id);
          localStorage.setItem("name",username)
          setAuth(false)
          navigate("/")
          setLoader(false);
        }else{
          toast(data)
          setLoader(false)
          return; 
        }
        
      setEmail('');
      setUsername ('');
      setPassword('');
      } catch (error) {
        navigate('/networkerror');
      }
    }
  };


  const checkUsername = async () => {
    // Make a request to the server to check if the username exists
    try {
      const response = await axios.get(`${localUrl}/CheckUsername?username=${username}`);
      const result = response.data;
      // console.log(result)
      if(result === "USERNNAME AVAILABLE"){
        setIsUsernameTaken(false);
      }else if(result === "USER ALREADY EXISTS"){
        setIsUsernameTaken(true);
      }
    } catch (error) {
      navigate('/networkerror');
    }
  };

  return (
    <>

    <AuthHeader/>
      <ToastContainer />
    <div className="main-container">
      <div className="auth-container">
        <div className="container-left">
          {/* here add the image src */}
          <img src="http://d2aq2s4fglfv4y.cloudfront.net/sell-panel-poster.jpg" alt="Description of your image" />
        </div>
        <div className="container-right">
        <div className='heading'>
          <h2>Member Sign in</h2>
          <p>Access your MayleeFun account</p>
        </div>
        <div className='data-container'>
          <div className='box'>
            <h1>600+</h1>
            <p>Explicit Videos</p>
          </div>
          <div className='box'>
            <h1>40k</h1>
            <p>Fans and Followers</p>
          </div>
          <div className='box'>
            <h1>1m</h1>
            <p>Minutes of Streaming Content</p>
          </div>
          <div className='box'>
            <h1>0%</h1>
            <p>Top OF Content Creator</p>
          </div>
        </div>
          <form onSubmit={submitHandler} >
          <div className="form-group">
            <label>Enter User Name</label>
            <input
              type="text"
              value={username}
              style={{borderColor : error && "red"}}
              onChange={handleChangeUser}
              onBlur={checkUsername}
              required
              autoComplete="new-username"
            />
            {isUsernameTaken ? <span className='username-info-text'>This Username is already taken...</span>: <span className='username-warning-text'></span>}
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={handleChangeEmail}
              style={{borderColor : error && "red"}}
              required
              autoComplete="new-email"
            />
            
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              value={password}
              style={{borderColor : error && "red"}}
              onChange={handleChangePass}
              required
              autoComplete="new-password"
            />
          </div>
          {error && <p>* {error} Please Check It Once </p>}
          <button type="submit">{loader ? <Oval height={22} width={22} color="#fff" wrapperStyle={{}} wrapperClass="" visible={true} ariaLabel='oval-loading' secondaryColor="#000" strokeWidth={9} strokeWidthSecondary={2}/> : "SIGN UP"}</button>
        </form>
      

          <div className='links'>
            <p>Already have an account? <Link to="/login">Login here</Link></p>
            <p>By signing up, you agree to our <Link to="/terms-and-condition">Terms and Conditions</Link>.</p>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Signup;







































