import React, { useContext } from 'react';
import "../assets/css/marqee.css";
import { firstProvider } from '../App';

const Marqee = () => {

  const arr = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]

  const [catSec,setCatSec,redVideos,setRedVideos,headerData,setHeaderData] = useContext(firstProvider)
  return (
    <div className="marquee" >
    <div className="announcment_bar" >
    {/* <p>{headerData.marquee_text}</p> */}
    {arr.map((item)=>(
      <p key={item} >{headerData.marquee_text}</p>
    ))}
    </div>
    </div>
  )
}

export default Marqee
