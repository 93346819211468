import React, { useState, useContext} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './auth.css';
import { ToastContainer, toast } from 'react-toastify';
import AuthHeader from './AuthHeader';
import { Oval } from 'react-loader-spinner'
import { BoughtVideosProvider, contextProvider ,firstProvider, CoinsProvider, CartProvider} from "../App";

function Login() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchValue,setSearchValue,searchTag,setSearchTag,auth,setAuth] = useContext(contextProvider)
  const navigate = useNavigate()
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loader,setLoader] = useState(false)
  const {boughtVids, setBoughtVids} = useContext(BoughtVideosProvider);
  const {coins, setCoins}= useContext(CoinsProvider);
  const {isCartOpen, setIsCartOpen} = useContext(CartProvider);
  const [popUp, setPopUp] = useState(false);

  const localUrl = process.env.REACT_APP_LOCAL_URL;

  const handleChangeUser = (event) => {
    const value = event.target.value;
    if (!value.replace(/[^\`\']/gi, '')) {
      setUsername(value);
    }
  };
  const handleChangePass = (event) => {
    const value = event.target.value;
    if (!value.replace(/[^\`\']/gi, '')) {
      setPassword(value)
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoader(true)

    try {
      const response = await fetch(`${localUrl}/account_login?username=${username}&password=${password}`)
    const result = await response.json()

    if(typeof result === 'object'){
      let token_id = result[1];
      let username = result[2];
      localStorage.setItem("token_id",token_id);
      localStorage.setItem("name",username)
      setAuth(false)
      setLoader(false)
      navigate('/');  
      window.location.reload()
    }else{
      toast(result)
      setLoader(false)
      return;
    }
    setUsername ('');
    setPassword('');
    } catch (error) {
      navigate('/networkerror');
    }
  };


  function showPopUp(){
    setPopUp(true);
  }
  return (
<>
<ToastContainer/>
<AuthHeader/>
{popUp === true ? 
  <div className='forgot-pass-popup'>
    <div className='forgot-pass-em' onClick={()=> setPopUp(false)}></div>
    <div className='forgot-pass-contet'>
      <div className='fogot-pass-text' >
        Do write mail on <a href="mailto:someone@example.com"> Test@gmail.com </a>
        &nbsp; and we will help recover your password.
      </div>
      <div className='close-btn'><p onClick={()=> setPopUp(false)}>close</p></div>
    </div>
  </div>
  : 
  <></> }
<div className="main-container">
    <div className="auth-container">
      <div className="container-left">
        {/* here add the image src */}
        <img src="http://d2aq2s4fglfv4y.cloudfront.net/sell-panel-poster.jpg" alt="Description of your image" />

      </div>
      <div className="container-right">
        <div className='heading'>
          <h2>Member Sign in</h2>
          <p>Access your MayleeFun account</p>
        </div>
        <div className='data-container'>
          <div className='box'>
            <h1>600+</h1>
            <p>Explicit Videos</p>
          </div>
          <div className='box'>
            <h1>40k</h1>
            <p>Fans and Followers</p>
          </div>
          <div className='box'>
            <h1>1m</h1>
            <p>Minutes of Streaming Content</p>
          </div>
          <div className='box'>
            <h1>0%</h1>
            <p>Top OF Content Creator</p>
          </div>
        </div>
        <form onSubmit={handleLogin}>
        <div className="form-group">
    <label>User name</label>
    <input
      type="username"
      value={username}
      onChange={handleChangeUser}
      required
      autoComplete="new-username"
    />
  </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={handleChangePass}
              required
              autoComplete="new-password"
            />
          </div>
          <button type="submit">{loader ? <Oval height={22} width={22} color="#fff" wrapperStyle={{}} wrapperClass="" visible={true} ariaLabel='oval-loading' secondaryColor="#000" strokeWidth={9} strokeWidthSecondary={2}/> : "LOGIN"}</button>
        </form>
        <div className='links'>
        <p >Don't have an account yet? <Link to="/signup">SIGN UP HERE</Link></p>
        <p ><Link onClick={()=>showPopUp()}>Forgot Username or Password?</Link></p>
        </div>
      </div>
    </div>
  </div>
</>

  );
}

export default Login;














