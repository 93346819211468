import React, { useContext, useEffect, useRef, useState } from "react";
import img1 from "../assets/img/logo-onlyfans-text-black-365d22635539ee3f62328cab67617995.svg";
import img2 from "../assets/img/of_white.svg";
import img3 from "../assets/img/of_white (2) (3).svg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {loadStripe} from '@stripe/stripe-js';
import { useNavigate } from "react-router-dom";
import { BoughtVideosProvider, contextProvider } from "../App";
import { Audio ,Oval } from 'react-loader-spinner'


const Card = ({
  title,
  rowid,
  getUrl,
  vidUrl,
  ShowVideohandler, 
  poster,
  duration,
  vip_price,
  video_desc,
  video_id,
  isLoading,
  vid_tag,
  isbought,
  isCartOpen,
  setIsCartOpen,
  doIBought,
  vidType,
  video_id_part
}) => {
  const [searchValue,setSearchValue,searchTag,setSearchTag,auth,setAuth ] = useContext(contextProvider)
  const navigate = useNavigate()
  const [showFullDescription, setFullDescription] = useState(false);
  const [isVideoHovered, setVideoHovered] = useState(false);
  const [loader,setLoader] = useState(false)
  const {boughtVids, setBoughtVids} = useContext(BoughtVideosProvider);
  const videoRef = useRef(null);

  const showFullDescriptionHandler = () => {  
    setFullDescription(!showFullDescription);
  };

  const handleVideoHover = (hovered) => {
    //change this later 
    setVideoHovered(hovered);
  };

  const description = showFullDescription
    ? video_desc
    : video_desc.slice(0, 80);

  const forVideo = () => {
    ShowVideohandler(true);
    getUrl(vidUrl);
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);

  const makePayment = async() =>{
    const stripe = await loadStripe('pk_test_51Nen7hFpqCcVujOt8ln3Ot2iQjvuAjN8Uqega6SC8YnQOqeDdnTcoquE8MHMACYLUpcvW52QtE80CJyYBeMoF5po0033VmtL9z');
    const body = {
        products:{
          id:1,
          name:title,
          amount:100,
          image:poster
        }
    }
    const headers = {
        "Content-Type":"application/json"
    }
    
    // const response = await fetch("http://localhost:4000/api/create-checkout-session",{
      const response = await fetch("https://pleasant-goat-sunglasses.cyclic.app/api/create-checkout-session",{
        method:"POST",
        headers:headers,
        body:JSON.stringify(body)
    });

    const session = await response.json();

    const result = stripe.redirectToCheckout({
        sessionId:session.id
    })

    if(result.error){
        console.log(result.error);
    }
}

const videoPay = (obj) =>{
  // !auth ? makePayment() : navigate("/login");
  setLoader(true);
  setIsCartOpen(true)
//checking if the user already bought that vid using vid id 
  const exists = boughtVids.some(item => (item.video_id === obj.video_id));

  if (!exists) {
    // If obj doesn't exist, push it to the array
    setBoughtVids(prevBoughtVids => [...prevBoughtVids, obj]);
  
  } else {
    // If obj already exists, handle accordingly (e.g., show a message)
    alert("Already added to the cart")
  }
  setTimeout(() => {
    setLoader(false)
  }, 400);
}

useEffect(()=>{
  localStorage.setItem("cart-vids", JSON.stringify(boughtVids));
},[boughtVids])

useEffect(()=>{
var xt = setTimeout(()=>{
setLoader(false)
},10500)
return () =>{
  clearTimeout(xt)
}
},[loader])


const getButtons = ()=>{
  if(doIBought === true || vidType === "my_vid"){
    return <button className="play-btn" onClick={()=>forVideo()}>Play</button>;
  }else if(vidType === "premium"){
    return (<>
     <button
              onClick={() => {
                window.location.href =
                  "https://onlyfans.com/my/chats/chat/126991416/?q=funvip";
              }}
              className="btn-1"
            >
              <img
                onDragStart={(event) => event.preventDefault()}
                src={img3}
                alt=""
                className="main-image"
              ></img>
              <h1>VIP</h1>
            </button>
            <button
              onClick={() => {
                window.location.href =
                  "https://onlyfans.com/my/chats/chat/130013409/?q=mayleefun";
              }}
              className="btn-2"
            >
              <img
                onDragStart={(event) => event.preventDefault()}
                src={img3}
                alt=""
                className="main-image"
              ></img>
              <h1>FREE</h1>
            </button>
            </>)
  }else if(vidType === "quickies"){
    return (
      
      <button className="buy-btn"  onClick={()=>videoPay({title, poster, video_id, vip_price, video_desc, rowid})}>WATCH THE FULL SCENE FOR ${vip_price}
      {loader ?
        <Oval height={22} width={22} color="#fff" wrapperStyle={{}} wrapperClass="" visible={true} ariaLabel='oval-loading' secondaryColor="#000" strokeWidth={9} strokeWidthSecondary={2}/> :
        <svg xmlns="http://www.w3.org/2000/svg" width="36.825" height="30.688" viewBox="0 0 36.825 30.688"><g id="cart_mf" transform="translate(0 -4)"><path id="Path_3" data-name="Path 3" d="M29.787,27.016H15.359a3.833,3.833,0,0,1-3.684-2.786L6.8,7.2a2.3,2.3,0,0,0-2.21-1.669H.767A.767.767,0,1,1,.767,4H4.588A3.832,3.832,0,0,1,8.272,6.786l.962,3.351H34.523a2.3,2.3,0,0,1,2.2,2.961l-3.3,11.278A3.83,3.83,0,0,1,29.787,27.016ZM9.673,11.672l3.478,12.14a2.293,2.293,0,0,0,2.208,1.669H29.787A2.317,2.317,0,0,0,31.96,23.92l3.3-11.259a.768.768,0,0,0-.735-.988Zm7.206,23.016a3.069,3.069,0,1,1,3.069-3.069A3.072,3.072,0,0,1,16.878,34.688Zm0-4.6a1.536,1.536,0,1,0,1.467,1.534A1.536,1.536,0,0,0,16.878,30.085Zm10.741,4.6a3.069,3.069,0,1,1,3.069-3.069A3.072,3.072,0,0,1,27.619,34.688Zm0-4.6a1.536,1.536,0,1,0,1.467,1.534,1.536,1.536,0,0,0-1.467-1.534Z" fill="#fff"></path></g></svg>
        } </button>
    )
  }else if(vidType === "exc-vids"){
    return (
      <button className="buy-btn"  onClick={()=>videoPay({title, poster, video_id, vip_price, video_desc, rowid})}>WATCH THE FULL SCENE FOR ${vip_price}
      {loader ?
        <Oval height={22} width={22} color="#fff" wrapperStyle={{}} wrapperClass="" visible={true} ariaLabel='oval-loading' secondaryColor="#000" strokeWidth={9} strokeWidthSecondary={2}/> :
        <svg xmlns="http://www.w3.org/2000/svg" width="36.825" height="30.688" viewBox="0 0 36.825 30.688"><g id="cart_mf" transform="translate(0 -4)"><path id="Path_3" data-name="Path 3" d="M29.787,27.016H15.359a3.833,3.833,0,0,1-3.684-2.786L6.8,7.2a2.3,2.3,0,0,0-2.21-1.669H.767A.767.767,0,1,1,.767,4H4.588A3.832,3.832,0,0,1,8.272,6.786l.962,3.351H34.523a2.3,2.3,0,0,1,2.2,2.961l-3.3,11.278A3.83,3.83,0,0,1,29.787,27.016ZM9.673,11.672l3.478,12.14a2.293,2.293,0,0,0,2.208,1.669H29.787A2.317,2.317,0,0,0,31.96,23.92l3.3-11.259a.768.768,0,0,0-.735-.988Zm7.206,23.016a3.069,3.069,0,1,1,3.069-3.069A3.072,3.072,0,0,1,16.878,34.688Zm0-4.6a1.536,1.536,0,1,0,1.467,1.534A1.536,1.536,0,0,0,16.878,30.085Zm10.741,4.6a3.069,3.069,0,1,1,3.069-3.069A3.072,3.072,0,0,1,27.619,34.688Zm0-4.6a1.536,1.536,0,1,0,1.467,1.534,1.536,1.536,0,0,0-1.467-1.534Z" fill="#fff"></path></g></svg>
        } </button>
    );
  }
}

  return (
    <React.Fragment>
      
      {isLoading ? (
        <>
        
          <div className="card">
            
            <div className="card-image">
              <SkeletonTheme highlightColor="#F8AD00">
                {" "}
                <div style={{ width: "100%", height: "100%" }}>
                  <Skeleton height={"100%"} />
                </div>{" "}
              </SkeletonTheme>
            </div>
            <h4></h4>
            <p>
              <SkeletonTheme highlightColor="#F8AD00">
                {" "}
                <div style={{ width: "100%", height: "100%" }}>
                  <Skeleton height={"100%"} />
                </div>{" "}
                
              </SkeletonTheme>
            </p>
            <div className="buttons">
              {/* <button className="btn-1">
                <img src={img3} alt="" className="main-image"></img>
                <h1>VIP</h1>
              </button>
              <button className="btn-2">
                <img src={img3} alt="" className="main-image"></img>
                <h1>FREE</h1>
              </button> */}
              <button className="buy-btn"  >BUY NOW</button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="card">
            <div
              className="card-image"
              onMouseEnter={() => handleVideoHover(true)}
              onMouseLeave={() => handleVideoHover(false)}
              onClick={forVideo}
            >
              {isVideoHovered ? (
                <video
                  ref={videoRef}
                  src={vidUrl}
                  autoPlay
                  muted
                  loop
                  className="video-overlay"
                />
              ) : (
                <i className="bx bx-play-circle"></i>
              )}
              <span className="video_no">{vid_tag} : {video_id} {video_id_part && "Part "}{video_id_part}</span>
              <span className="duration">{duration}</span>
               <img
                onDragStart={(event) => event.preventDefault()}
                src={poster}
                alt="Card Image"
              /> 
            </div>
            <h4>{title}</h4>
            <p>
              {description}{" "}
              <span
                onClick={showFullDescriptionHandler}
                className="readmoreless"
              >
                Read {showFullDescription ? "Less" : "More"}
              </span>{" "}
            </p>
          
            
            {/* <button className="play-btn" onClick={()=>forVideo()}>Play</button> */}
            
            <div className="buttons">
              {getButtons()}
              {/* <button
              onClick={() => {
                window.location.href =
                  "https://onlyfans.com/my/chats/chat/126991416/?q=funvip";
              }}
              className="btn-1"
            >
              <img
                onDragStart={(event) => event.preventDefault()}
                src={img3}
                alt=""
                className="main-image"
              ></img>
              <h1>VIP</h1>
            </button>
            <button
              onClick={() => {
                window.location.href =
                  "https://onlyfans.com/my/chats/chat/130013409/?q=mayleefun";
              }}
              className="btn-2"
            >
              <img
                onDragStart={(event) => event.preventDefault()}
                src={img3}
                alt=""
                className="main-image"
              ></img>
              <h1>FREE</h1>
            </button>
            
            <button className="buy-btn"  onClick={()=>videoPay({title, poster, video_id, vip_price, video_desc, rowid})}> {loader ?
              <Oval height={22} width={22} color="#fff" wrapperStyle={{}} wrapperClass="" visible={true} ariaLabel='oval-loading' secondaryColor="#000" strokeWidth={9} strokeWidthSecondary={2}/> :
              <svg xmlns="http://www.w3.org/2000/svg" width="36.825" height="30.688" viewBox="0 0 36.825 30.688"><g id="cart_mf" transform="translate(0 -4)"><path id="Path_3" data-name="Path 3" d="M29.787,27.016H15.359a3.833,3.833,0,0,1-3.684-2.786L6.8,7.2a2.3,2.3,0,0,0-2.21-1.669H.767A.767.767,0,1,1,.767,4H4.588A3.832,3.832,0,0,1,8.272,6.786l.962,3.351H34.523a2.3,2.3,0,0,1,2.2,2.961l-3.3,11.278A3.83,3.83,0,0,1,29.787,27.016ZM9.673,11.672l3.478,12.14a2.293,2.293,0,0,0,2.208,1.669H29.787A2.317,2.317,0,0,0,31.96,23.92l3.3-11.259a.768.768,0,0,0-.735-.988Zm7.206,23.016a3.069,3.069,0,1,1,3.069-3.069A3.072,3.072,0,0,1,16.878,34.688Zm0-4.6a1.536,1.536,0,1,0,1.467,1.534A1.536,1.536,0,0,0,16.878,30.085Zm10.741,4.6a3.069,3.069,0,1,1,3.069-3.069A3.072,3.072,0,0,1,27.619,34.688Zm0-4.6a1.536,1.536,0,1,0,1.467,1.534,1.536,1.536,0,0,0-1.467-1.534Z" fill="#fff"></path></g></svg>
              }</button> */}
            </div>
          </div>
        </>
      )}
      
    </React.Fragment>

  );
};

export default Card;
