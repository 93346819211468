import React from 'react'
import "../assets/css/redVideos.css"
import Header from '../componant/Header'

const RedVideos = () => {
  return (
    <section className='red-videos' >
        <h1>Coming Soon</h1>
        <p>NOW YOU CAN BUY VIDEOS DIRECTLY FROM HERE. WE WILL BE LAUNCHING SOON.<br/>
        
        IF YOU HAVE ANY CUSTOM REQUEST FOR VIDEO, PLEASE FEEL FREE TO EMAIL ME ON <br/>
        <a className='red-email' href='mailto:fun69thai@gmail.com'> fun69thai@gmail.com </a>
        </p>
    </section>
  )
}

export default RedVideos